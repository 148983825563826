import { useCallback, useEffect, useMemo, useState } from "react";
import NavBar from "../../components/NavBar";
import BinaryOptionMarketAPI from "../../modules/BinaryOptionMarketAPI";
import { NETWORKS } from "../../utils/constants";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import MarketItem from "../../components/ParadyzeMarket";
import TermsModal from "../../components/TermsModal";
import Footer from "../../components/Footer";
import { useDispatch } from "react-redux";
import { openTermsModal } from "../../redux/reducers/modal";
import TokenAccessModal from "../../components/TokenAccessModal";
import ErrorModal from "../../components/ErrorModal";
import SwapModal from "../../components/SwapModal";
import { MoonLoader } from "react-spinners"
import MainnetCountdownModal from "@/components/MainnetCountdownModal";

const MARKETS_QUERY = gql`
query getMarkets($time: timestamptz) {
  option_markets_paradyzemarket {
    description
    expiration_timestamp
    id
    settlement_timestamp
    title
    image_url
    option_markets {
      ticker
      settlement_timestamp
      paradyze_market_id
      option_1
      option_0
      on_chain_market_created
      market_id
      market_description
      image_url
      id
      expiration_timestamp
      prices(order_by: {time: desc}, limit: 1){
        time
        price
        filled_shares
      }
    }
  }
}
`

const Main = () => {
    const dispatch = useDispatch();

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const [allMarkets, setAllMarkets] = useState(null);

    const [openMarkets, setOpenMarkets] = useState([]);
    const [pendingMarkets, setPendingMarkets] = useState([]);
    const [settledMarkets, setSettledMarkets] = useState([]);

    const [loading, setLoading] = useState(true);
    const [onChainFetched, setOnChainFetched] = useState(false);

    const [currentTime] = useState(moment())

    const { data } = useQuery(MARKETS_QUERY, {
        variables: {
            time: currentTime,
        },
        pollInterval: 5000
    })

    useEffect(() => {
        const termsAccepted = localStorage.getItem('termsAccepted');
        console.log("terms accepted: ", termsAccepted)
        if (!termsAccepted) {
            dispatch(openTermsModal());
        }
    }, [dispatch])

    const getOptionMarket = useCallback(async (marketId) => {
        const module = new BinaryOptionMarketAPI(networkConfig);
        try {
            const market = await module.fetchBinaryOptionMarket(marketId);
            console.log("got option market")
            return market
        } catch (error) {
            console.error('Failed to fetch option markets:', error);
            throw error;
        }
    }, [networkConfig]);

    useEffect(() => {
        if (!data) return
        setAllMarkets(data.option_markets_paradyzemarket)
    }, [data])

    useEffect(() => {
        if (!allMarkets) return;

        setLoading(true);

        const fetchAndUpdateMarkets = async () => {
            const updatedMarkets = await Promise.all(
                allMarkets.map(async (market) => {
                    const updatedOptionMarkets = await Promise.all(
                        market.option_markets.map(async (optionMarket) => {
                            if (!optionMarket.market_id) {
                                return {
                                    ...optionMarket,
                                    onChainMarket: null,
                                };
                            }

                            console.log("call get option market")
                            const matchingOnChainMarket = await getOptionMarket(optionMarket.market_id);
                            await new Promise(resolve => setTimeout(resolve, 500));

                            return {
                                ...optionMarket,
                                onChainMarket: matchingOnChainMarket || null,
                            };
                        })
                    );
                    return {
                        ...market,
                        option_markets: updatedOptionMarkets,
                    };
                })
            );
            const now = moment();
            const openMarketsArray = updatedMarkets.filter(
                (market) => moment(market.expiration_timestamp).isAfter(now)
            );
            const pendingMarketsArray = updatedMarkets.filter(
                (market) =>
                    moment(market.expiration_timestamp).isBefore(now) &&
                    moment(market.settlement_timestamp).isAfter(now)
            );
            const settledMarketsArray = updatedMarkets.filter(
                (market) => moment(market.settlement_timestamp).isBefore(now)
            );

            setOpenMarkets(openMarketsArray);
            setPendingMarkets(pendingMarketsArray);
            setSettledMarkets(settledMarketsArray);

            setOnChainFetched(true)
        };

        fetchAndUpdateMarkets().then(() => {
            console.log("set loading false")
            setLoading(false)
        });
    }, [allMarkets, getOptionMarket]);

    return (
        <>
            <MainnetCountdownModal />
            <TermsModal />
            <TokenAccessModal />
            <ErrorModal />
            <SwapModal />

            <div className="flex flex-col bg-bg-image bg-fixed bg-cover min-h-screen font-aileron font-regular text-gold">
                <NavBar />
                {loading && !onChainFetched && <MoonLoader loading={loading} color="gold" size={80} className="m-auto mt-20" />}

                <div className="flex-grow">
                    {allMarkets && onChainFetched &&
                        <div className="mx-1 md:mx-5 mt-20 pb-10">
                            <div>
                                <div className="text-left">
                                    <div
                                        className="inline-block px-2 lg:px-8 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end text-xl font-bold"
                                    >
                                        Open Markets
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-8 p-2 lg:py-2 lg:px-8">
                                    {openMarkets.length > 0 && openMarkets
                                        .sort((a, b) => moment(b.settlement_timestamp).isAfter(moment(a.settlement_timestamp)) ? 1 : -1)
                                        .map(market => (
                                            <div
                                                key={market.id}
                                            >
                                                <MarketItem market={market} />
                                            </div>
                                        ))}
                                    {openMarkets.length == 0 &&
                                        <div className="text-left my-5">
                                            No open markets right now
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="text-left">
                                    <div
                                        className="inline-block px-2 lg:px-8 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end text-xl font-bold"
                                    >
                                        Pending Settlement
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2  gap-2 md:gap-4 lg:gap-8 p-2 lg:py-2 lg:px-8">
                                    {pendingMarkets.length > 0 && pendingMarkets
                                        .sort((a, b) => moment(b.settlement_timestamp).isAfter(moment(a.settlement_timestamp)) ? 1 : -1)
                                        .map(market => (
                                            <div
                                                key={market.id}
                                            >
                                                <MarketItem market={market} />
                                            </div>
                                        ))}
                                    {pendingMarkets.length == 0 &&
                                        <div className="text-left my-5">
                                            No markets pending settlement
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="text-left">
                                    <div
                                        className="inline-block px-2 lg:px-8 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end text-xl font-bold"
                                    >
                                        Settled Markets
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2  gap-2 md:gap-4 lg:gap-8 p-2 lg:py-2 lg:px-8">
                                    {settledMarkets.length > 0 && settledMarkets
                                        .sort((a, b) => moment(b.settlement_timestamp).isAfter(moment(a.settlement_timestamp)) ? 1 : -1)
                                        .map(market => (
                                            <div
                                                key={market.id}
                                            >
                                                <MarketItem market={market} />
                                            </div>
                                        ))}
                                    {settledMarkets.length == 0 &&
                                        <div className="text-left my-5">
                                            No settled markets yet
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Main;
