import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    connectedAddress: null,
    connectedWallet: null
};

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setConnectedAddress: (state, action) => {
            state.connectedAddress = action.payload;
        },
        clearConnectedAddress: (state) => {
            state.connectedAddress = null;
        },
        setConnectedWallet: (state, action) => {
            state.connectedWallet = action.payload;
        },
        clearConnectedWallet: (state) => {
            state.connectedWallet = null;
        }
    },
});

export const { setConnectedAddress, clearConnectedAddress, setConnectedWallet, clearConnectedWallet } = walletSlice.actions;
export default walletSlice.reducer;