
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isTermsModalOpen: true,
    isTokenAccessModalOpen: false,
    isSwapModalOpen: false,
    isErrorModalOpen: false,
    isWalletConnectOpen: false,
    errorMessage: '',
};

const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openTermsModal(state) {
            state.isTermsModalOpen = true;
        },
        closeTermsModal(state) {
            state.isTermsModalOpen = false;
        },
        openTokenAccessModal(state) {
            state.isTokenAccessModalOpen = true;
        },
        closeTokenAccessModal(state) {
            state.isTokenAccessModalOpen = false;
        },
        openSwapModal(state) {
            state.isSwapModalOpen = true;
        },
        closeSwapModal(state) {
            state.isSwapModalOpen = false;
        },
        toggleTermsModal(state) {
            state.isTermsModalOpen = !state.isTermsModalOpen;
        },
        toggleTokenAccessModal(state) {
            state.isTokenAccessModalOpen = !state.isTokenAccessModalOpen;
        },
        openErrorModal(state, action) {
            state.isErrorModalOpen = true;
            state.errorMessage = action.payload;
        },
        closeErrorModal(state) {
            state.isErrorModalOpen = false;
            state.errorMessage = '';
        },
        openWalletConnectModal(state) {
            state.isWalletConnectOpen = true;
        },
        closeWalletConnectModal(state) {
            state.isWalletConnectOpen = false;
        },
    },
});

export const {
    openTermsModal,
    closeTermsModal,
    openTokenAccessModal,
    closeTokenAccessModal,
    toggleTermsModal,
    toggleTokenAccessModal,
    openErrorModal,
    closeErrorModal,
    openSwapModal,
    closeSwapModal,
    openWalletConnectModal,
    closeWalletConnectModal
} = modalSlice.actions;

export default modalSlice.reducer;
