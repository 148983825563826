import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NETWORKS } from "../../utils/constants";
import { IndexerGrpcAccountApi } from "@injectivelabs/sdk-ts";
import MyOrderHistory from "../MyOrderHIstory";
import TradeHistoryTable from "../TradeHistoryTable";
import OrderHistoryTable from "../OrderHistoryTable";


const MarketOrderHistory = (props) => {

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const [subAccount, setSubAccount] = useState(null)

    const [onChainMarket, setOnChainMarket] = useState(null)

    const [tradeView, setTradeView] = useState<'trades' | 'yourTrades' | 'orders' | 'yourOrders'>('trades')
    const [orders, setOrders] = useState([])
    const [trades, setTrades] = useState([])

    const getSubAccount = useCallback(async (injectiveAddress: string) => {
        const indexerGrpcAccountApi = new IndexerGrpcAccountApi(networkConfig.indexer)
        const subaccountsList = await indexerGrpcAccountApi.fetchSubaccountsList(
            injectiveAddress,
        )
        setSubAccount(subaccountsList[0])
        return subaccountsList
    }, [networkConfig])

    useEffect(() => {
        setOnChainMarket(props.onChainMarket)
        setOrders(props.orders.sort((a, b) => b.createdAt - a.createdAt))
        setTrades(props.trades.sort((a, b) => b.executedAt - a.executedAt))
    }, [props.onChainMarket, props.orders, props.trades])

    useEffect(() => {
        if (!connectedAddress) {
            setSubAccount(null)
        }
        else {
            getSubAccount(connectedAddress)
        }
    }, [connectedAddress, getSubAccount])

    return (
        <div
            className='bg-black bg-opacity-50 backdrop-blur-sm  rounded-lg p-5 shadow-lg text-gold'
        >
            <div className="flex space-x-2 font-bold">
                <button
                    className={`h-8 px-4 text-xs ${tradeView === 'trades' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                        }`}
                    onClick={() => setTradeView('trades')}
                >
                    All Trades
                </button>
                <button
                    className={`h-8 px-4 text-xs ${tradeView === 'yourTrades' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                        }`}
                    onClick={() => setTradeView('yourTrades')}
                >
                    Your Trades
                </button>
                <button
                    className={`h-8 px-4 text-xs ${tradeView === 'orders' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                        }`}
                    onClick={() => setTradeView('orders')}
                >
                    All orders
                </button>
                <button
                    className={`h-8 px-4 text-xs ${tradeView === 'yourOrders' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                        }`}
                    onClick={() => setTradeView('yourOrders')}
                >
                    Your orders
                </button>
            </div>
            {tradeView == "trades" &&
                <TradeHistoryTable
                    trades={trades.filter(trade => trade.state !== "canceled")}
                    onChainMarket={onChainMarket}
                    showVolume={true}
                />
            }
            {tradeView == "yourTrades" &&
                <TradeHistoryTable
                    trades={trades.filter(trade => trade.subaccountId == subAccount && trade.state !== "canceled")}
                    onChainMarket={onChainMarket}
                    showVolume={false}
                />
            }
            {tradeView == "orders" &&
                <OrderHistoryTable
                    orders={orders}
                    onChainMarket={onChainMarket}
                />
            }
            {tradeView == "yourOrders" &&
                <MyOrderHistory
                    onChainMarket={onChainMarket}
                    orders={orders}
                    setOrdersLoaded={props.setOrdersLoaded}
                />
            }
        </div>
    );
};

export default MarketOrderHistory;
