import { useEffect, useState } from "react";
import logo from "../../assets/ParadyzeLogowText.webp"
import Terms from "../Terms";
import { useDispatch, useSelector } from "react-redux";
import { closeTermsModal } from "../../redux/reducers/modal";
import { Link } from "react-router-dom";

export default function TermsModal() {
    const isOpen = useSelector((state) => state.modals.isTermsModalOpen);
    const dispatch = useDispatch();

    const [isAccepted, setIsAccepted] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const handleAccept = () => {
        setIsAccepted(!isAccepted);
        localStorage.setItem('termsAccepted', (!isAccepted).toString());
    };

    const closeModal = () => {
        if (isAccepted) {
            dispatch(closeTermsModal())
        }
    };

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);

        return () => window.removeEventListener("resize", checkIsMobile);
    }, []);

    return (
        <div
            className="font-aileron font-regular text-white"
        >
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div
                        className={`bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg p-6 rounded-lg shadow-lg mx-5 
                            ${isMobile ? "max-h-[85vh]" : "max-h-[90vh]"} overflow-y-scroll`}
                    >
                        <img className="m-auto" src={logo} width={150} />
                        <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
                        <p className="text-sm mb-4">
                            Please read and accept the terms and conditions before proceeding
                            to use this site. Your acceptance is required to continue.
                        </p>
                        <p className="text-sm mb-4">
                            Please note that residents of the United States are not permitted to access or use this web application
                        </p>
                        <div className="max-h-80 overflow-y-auto border p-2 mb-4 text-sm">
                            <Terms />
                        </div>
                        <Link to={"/privacy-policy"} className="text-left underline">Link to Privacy policy</Link>
                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                id="acceptTerms"
                                checked={isAccepted}
                                onChange={handleAccept}
                                className="mr-2"
                            />
                            <label htmlFor="acceptTerms" className="text-base my-2">
                                By clicking accept, I accept the terms and conditions and the privacy policy
                            </label>
                        </div>
                        <button
                            onClick={closeModal}
                            disabled={!isAccepted}
                            className={`w-full px-4 py-2 rounded-lg font-semibold ${isAccepted ? "bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black" : "bg-gray-400"
                                }`}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
