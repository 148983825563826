import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import Main from './pages/Main';
import { ApolloProvider } from '@apollo/client';
import client from './apollo/client';
import './App.css'
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { PersistGate } from 'redux-persist/integration/react';
import LeaderBoard from './pages/LeaderBoard';
import MarketDetails from './pages/MarketDetails';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <Router>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/market/:marketId" element={<MarketDetails />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/leader-board" element={<LeaderBoard />} />
            </Routes>
          </Router>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
