import { TransactionException } from '@injectivelabs/exceptions';
import { BaseAccount, BroadcastModeKeplr, ChainRestAuthApi, ChainRestTendermintApi, CosmosTxV1Beta1Tx, createTransaction, getTxRawFromTxRawOrDirectSignResponse, TxRaw, TxRestApi } from '@injectivelabs/sdk-ts';
import { BigNumberInBase, DEFAULT_BLOCK_TIMEOUT_HEIGHT, getStdFee } from '@injectivelabs/utils';
import { Buffer } from "buffer";
import { store } from "../redux/store"

export const getWallet = () => {
    const { connectedWallet } = store.getState().wallet;

    let wallet;
    if (connectedWallet === 'leap') {
        if (!window.leap) {
            throw new Error('Leap extension not installed');
        }
        console.log("LEAP WALLET")
        wallet = window.leap;
    } else if (connectedWallet === 'keplr') {
        if (!window.keplr) {
            throw new Error('Keplr extension not installed');
        }
        wallet = window.keplr;
        console.log("KEPLR WALLET")
    }
    if (!wallet) {
        wallet = window.keplr
    }
    return wallet
}

export const getOfflineSigner = async (chainId) => {
    const wallet = getWallet()
    await wallet.enable(chainId);
    const offlineSigner = wallet.getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    const key = await wallet.getKey(chainId);

    return { offlineSigner, accounts, key };
};

export const broadcastTx = async (chainId: string, txRaw: TxRaw) => {
    const wallet = await getWallet();
    const result = await wallet.sendTx(
        chainId,
        CosmosTxV1Beta1Tx.TxRaw.encode(txRaw).finish(),
        BroadcastModeKeplr.Sync
    );

    if (!result || result.length === 0) {
        throw new TransactionException(
            new Error("Transaction failed to be broadcasted"),
            { contextModule: "Keplr" }
        );
    }

    return Buffer.from(result).toString("hex");
}

export const handleSendTx = async (
    networkConfig: any,
    pubKey: any,
    msg: any,
    injectiveAddress: string,
    offlineSigner: { signDirect: (arg0: any, arg1: CosmosTxV1Beta1Tx.SignDoc) => any; },
    gas: any = null
) => {
    console.log(`send messages in tx:`, msg)
    try {
        const chainRestAuthApi = new ChainRestAuthApi(networkConfig.rest);
        const chainRestTendermintApi = new ChainRestTendermintApi(networkConfig.rest);

        const latestBlock = await chainRestTendermintApi.fetchLatestBlock();
        const latestHeight = latestBlock.header.height;
        const timeoutHeight = new BigNumberInBase(latestHeight).plus(
            DEFAULT_BLOCK_TIMEOUT_HEIGHT + 60
        );

        const accountDetailsResponse = await chainRestAuthApi.fetchAccount(
            injectiveAddress
        );
        const baseAccount = BaseAccount.fromRestApi(accountDetailsResponse);

        const { signDoc } = createTransaction({
            pubKey: pubKey,
            chainId: networkConfig.chainId,
            fee: gas ?? getStdFee({}),
            message: msg,
            sequence: baseAccount.sequence,
            timeoutHeight: timeoutHeight.toNumber(),
            accountNumber: baseAccount.accountNumber,
        });

        const directSignResponse = await offlineSigner.signDirect(
            injectiveAddress,
            signDoc
        );

        const txRaw = getTxRawFromTxRawOrDirectSignResponse(directSignResponse);
        const txHash = await broadcastTx(networkConfig.chainId, txRaw);
        const response = await new TxRestApi(networkConfig.rest).fetchTxPoll(txHash);
        return response
    }
    catch (e) {
        console.log(e)
        throw e
    }
}