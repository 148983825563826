

const Terms = () => {

    return (
        <div className="text-justify leading-relaxed mx-2">
            <h3 className="text-center mb-4">Effective Date: September 2024</h3>

            <p className="mb-4">
                Please note that residents of the United States are not permitted to access or use this web application.
            </p>

            <p>
                Chainflow Ltd, a company registered under the statutes of Cyprus (referred to as the "Company"), operates this user interface accessible on
                our website (the "Interface").
            </p>

            <p className="mt-4">
                These "Terms" include these Terms and Conditions, as well as any documents and supplementary terms incorporated by reference, including
                other terms, conditions, or agreements that the Company ("we," "us," or "our") provides to you or the entity you represent ("you" or
                "your"). They govern your access to and use of our websites, including the Interface; web applications; mobile applications; and any related
                sites linked through the Interface, us, or our affiliates (collectively, along with any materials and services available therein and any
                successor websites or applications, the "Site").
            </p>

            <p className="mt-4">
                By clicking "I agree" (or similar wording) to accept these Terms, acknowledging these Terms by other means, or accessing or using the Site
                or Services, you agree to be bound by and comply with these Terms, including the mandatory arbitration clause in Section 16. If you disagree
                with any aspect of these Terms, you are prohibited from accessing or using the Site or Services.
            </p>

            <p className="font-bold mt-4">
                PLEASE READ THESE TERMS CAREFULLY.
            </p>

            <p>
                They outline your rights and responsibilities when accessing and using the Site and Services. These include interacting with order
                contracts, Supported Contracts (defined below), decentralized applications, APIs, and all software provided by the Company to facilitate the
                operation of the Interface. This includes, but is not limited to, the execution of contracts (“Contracts”) related to specific events,
                occurrences, or values (collectively, the “Services”). These Terms also contain disclaimers and limitations of liability concerning your use
                of the Site and Services, clearly defining your rights and obligations.
            </p>

            <p>
                By accessing or using the Site or Services, you acknowledge and agree that the Company does not offer brokerage, custody, exchange,
                execution, settlement, or clearing services of any kind. Therefore, the Company is not responsible for any aspect of the brokerage, custody,
                exchange, execution, settlement, or clearing of transactions. Before using any software developed by the Company, it is crucial to thoroughly
                review the disclosures and disclaimers outlined in Sections 14 and 15, as they provide important information regarding the legal obligations
                associated with your use of the Services.
            </p>

            <h3 className="font-bold mt-6">1. Revisions to These Terms</h3>
            <p>
                We have the authority to change or update these Terms at any time and at our discretion. If we make any revisions, we will notify you by changing the “Effective Date” at the beginning of these Terms or through other appropriate channels. Unless otherwise indicated, all changes become effective immediately upon being published. By continuing to use the Site and Services after these changes are made, you agree to the updated Terms. If you do not accept the revised Terms, you must stop using the Site and Services.
            </p>

            <h3 className="font-bold mt-6">2. Use of Services</h3>
            <h4 className="font-semibold mt-2">User Representations and Warranties</h4>
            <p>
                By accessing or using the Site or Services, you represent and warrant that:
            </p>
            <ol className="list-decimal ml-8">
                <li>
                    <strong>Legal Authority and Capacity:</strong> If you are an individual, you are of the legal age in your jurisdiction
                    and have the legal capacity to enter into these Terms. “You” also refers to any entity you represent, and you have the
                    authority to bind that entity to these Terms.
                </li>
                <li>
                    <strong>Residency and Compliance:</strong> You are not a resident, citizen, or representative of any of the following
                    countries: Algeria, Bangladesh, Belarus, Bolivia, Burundi, Myanmar (Burma), Côte d’Ivoire (Ivory Coast), Crimea and
                    Sevastopol, Cuba, Democratic Republic of Congo, Ecuador, Iran, Iraq, Liberia, Libya, Mali, Morocco, Nepal, North Korea,
                    Somalia, Sudan, Syria, Venezuela, Yemen, Zimbabwe, or any other country that is subject to embargoes or similar sanctions
                    imposed by Canada, Cyprus, the United States, the United Kingdom, or the European Union.
                </li>
                <li>
                    <strong>Sanctions Lists:</strong> You are not listed on any sanctions lists maintained by the United States, the United Kingdom,
                    the European Union, the United Nations, or Cyprus.
                </li>
                <li>
                    <strong>Prohibited Circumvention:</strong> You agree not to employ VPN software or any other means to disguise your location
                    in an effort to evade the restrictions on accessing the Services.
                </li>
                <li>
                    <strong>Consent for Personal Data:</strong> You have secured all necessary permissions from any individuals whose personal
                    information you submit to us while using the Services.
                </li>
                <li>
                    <strong>Adherence to Laws:</strong> Your use and access of the Services fully comply with all relevant laws, regulations,
                    and guidelines (“Applicable Laws”).
                </li>
            </ol>

            <h3 className="font-bold mt-6">3. Acknowledgements and Limitations</h3>
            <ol className="list-decimal ml-8">
                <li>
                    <strong>Service Availability:</strong> The Site and Services may sometimes be unavailable or disrupted due to maintenance,
                    technical difficulties, or events beyond our control.
                </li>
                <li>
                    <strong>Right to Restrict Access:</strong> We reserve the right to limit, suspend, or alter your access to the Site and Services
                    at any time, particularly if we suspect a breach of these Terms or any relevant laws.
                </li>
                <li>
                    <strong>Service Modifications:</strong> We may change, suspend, or terminate any part of the Services at our sole discretion, without prior notice.
                </li>
                <li>
                    <strong>Information Accuracy:</strong> The Site is not an offer, solicitation, or recommendation, and any pricing or information provided is intended
                    solely for informational purposes.
                </li>
                <li>
                    <strong>No Agency Relationship:</strong> We do not act as your agent or represent any user of the Services.
                </li>
                <li>
                    <strong>User Responsibility:</strong> You are fully responsible for how you use the Services, including managing and securing your Digital Assets.
                </li>
                <li>
                    <strong>No Fiduciary Duty:</strong> We do not have any fiduciary duties to you. Any obligations or liabilities that might otherwise arise are expressly disclaimed to the maximum extent permitted by law.
                </li>
                <li>
                    <strong>Tax Obligations:</strong> You are responsible for reporting and paying any taxes resulting from your use of the Services.
                </li>
                <li>
                    <strong>Third-Party Interactions:</strong> We are not liable for any transactions or interactions you have with third parties through the Services.
                </li>
                <li>
                    <strong>Use of Feedback:</strong> We may use any feedback or suggestions you provide without any obligation to compensate you.
                </li>
            </ol>

            <h3 className="font-bold mt-6">4. Commitments</h3>
            <p>By using the Site or Services, you agree to:</p>

            <ol className="list-decimal ml-6">
                <li>
                    <strong>Legitimate Ownership of Digital Assets:</strong> Only transfer digital assets that you legally own or have the right to use.
                </li>
                <li>
                    <strong>Lawful Use:</strong> Comply with all relevant laws and avoid using the Site or Services in any regions where such use is prohibited.
                </li>
                <li>
                    <strong>Authorized Transactions:</strong> Confirm that any Digital Assets used in connection with the Services are either owned by you or that you have the proper authorization to use them.
                </li>
                <li>
                    <strong>Security and Accuracy:</strong> Keep the information you provide up-to-date, accurate, and complete, and ensure the security of your private keys, passwords, and other credentials.
                </li>
            </ol>

            <h3 className="font-bold mt-6">5. Pricing and Fees</h3>
            <p>
                You are accountable for all fees associated with blockchain transactions (e.g., gas fees) and any other fees
                displayed on the Site at the time of your transaction when using the Services. Our objective is to furnish precise
                fee estimates; however, actual fees may differ from those estimates.
            </p>

            <h3 className="font-bold mt-6">6. Absence of Fiduciary Obligations or Professional Advice</h3>
            <p>
                The Site and Services are designed to provide general information and should not be considered professional advice.
                Before making any decisions based on this information, it is recommended that you consult with qualified professionals.
                These Terms do not impose any fiduciary obligations on our part; we are solely responsible for providing the Interface
                and related Services.
            </p>

            <h3 className="font-bold mt-6">7. Actions that are Not Permitted</h3>
            <p>You consent to refrain from participating in any of the subsequent activities:</p>

            <ol className="list-decimal ml-6">
                <li>
                    <strong>Legal Violations:</strong> Failure to comply with any applicable laws, such as anti-money laundering, anti-terrorism
                    regulations, or sanctions programs.
                </li>
                <li>
                    <strong>Intellectual Property Infringement:</strong> Engage in transactions that involve counterfeit or unauthorized products or
                    materials that violate intellectual property rights.
                </li>
                <li>
                    <strong>Disruption of Services:</strong> Attempt to access the Site or Services without appropriate authorization, or interfere
                    with or disrupt them.
                </li>
                <li>
                    <strong>Market Manipulation:</strong> Participate in fraudulent activities that manipulate trading markets, such as spoofing or
                    wash trading.
                </li>
                <li>
                    <strong>Bypassing Restrictions:</strong> Utilize VPNs or other methods to circumvent geographical or regulatory restrictions
                    that have been imposed on the Services.
                </li>
                <li>
                    <strong>Malicious Software and Unauthorized Access:</strong> Incorporate viruses, malware, or detrimental code into the Site or
                    Services, or employ bots or scrapers.
                </li>
                <li>
                    <strong>Misrepresentation and Fraud:</strong> Engage in activities intended to defraud us or other users or provide misleading
                    or false information.
                </li>
                <li>
                    <strong>Managing Illicit Assets:</strong> Use the Services to manage Digital Assets that are the result of illicit activities.
                </li>
                <li>
                    <strong>Harassment and Hate Speech:</strong> Engage in behavior that is offensive, defamatory, or harassing, or incite violence
                    or hatred towards others.
                </li>
                <li>
                    <strong>Access from Prohibited Jurisdictions:</strong> Access the Site or Services from jurisdictions where such use is prohibited.
                </li>
                <li>
                    <strong>Impersonation:</strong> Falsely assert or misrepresent your affiliation or impersonate any individual or entity.
                </li>
                <li>
                    <strong>Violation of Third-Party Rights:</strong> Violate or infringe upon the rights of others.
                </li>
                <li>
                    <strong>Facilitating Prohibited Conduct:</strong> Encourage or assist any third party in engaging in any of the activities
                    prohibited by these Terms.
                </li>
            </ol>

            <h3 className="font-bold mt-6">8. User-Generated Content</h3>
            <p>
                By utilizing the Site or Services, you grant us a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, fully paid-up, transferable,
                and sublicensable license to utilize, reproduce, modify, display, distribute, and create derivative works from any content you provide through
                the Site or Services ("Your Content"). You confirm that you possess or possess the requisite rights to Your Content and that our utilization
                of it does not violate any third-party rights.
            </p>

            <h3 className="font-bold mt-6">9. Intellectual Property Rights</h3>
            <p>
                All trademarks, logos, and service marks displayed on the Site or through the Services are the property of the Company or its licensors.
                We hereby prohibit the use of these marks without our prior written consent. These Terms do not grant you any ownership rights in the
                Site or Services.
            </p>
            <p>
                The Services are non-custodial. You retain dominion over Digital Assets when you deposit them into any blockchain contracts. The Digital
                Assets you transfer into the contracts can only be managed by the private key associated with your Injective address. Withdrawals may be
                restricted to the Injective wallet that was the source of the funds in specific circumstances.
            </p>

            <h3 className="font-bold mt-6">10. External Links</h3>
            <p>
                Links to external websites or resources may be incorporated into the Services. We are not liable for any losses or damages that may arise
                from your use of these external sites, nor are we responsible for their availability or content.
            </p>

            <h3 className="font-bold mt-6">11. Modifications, Termination, and Suspension</h3>
            <p>
                At our sole discretion, we reserve the right to amend, suspend, or terminate your access to the Site or Services at any time, without prior
                notice, for any reason. Your authorization to utilize the Services terminates immediately upon termination. We are not responsible for any
                losses that may arise as a result of such actions. Provisions that are inherently intended to endure termination, such as Sections 11 through
                17, will continue to operate.
            </p>

            <h3 className="font-bold mt-6">12. Risk Assumption</h3>
            <p>
                By using the Services, you acknowledge and accept the inherent risks associated with blockchain technologies and Digital Assets,
                including but not limited to:
            </p>

            <ol className="list-decimal ml-6">
                <li>
                    <strong>Technological Risks:</strong> Blockchain technologies are in the experimental phase and may be accompanied by technical
                    issues, security vulnerabilities, and regulatory uncertainties.
                </li>
                <li>
                    <strong>Private Key Responsibility:</strong> You are the sole individual accountable for the protection of your private keys. Private
                    keys may be lost or compromised, which could lead to the irreversible loss of your digital assets.
                </li>
                <li>
                    <strong>Regulatory Measures:</strong> The Services may be impacted by regulatory investigations or actions, which could potentially
                    limit your ability to access or use them.
                </li>
                <li>
                    <strong>Technological Advancements:</strong> The Services and blockchain systems may be at risk due to technological advancements,
                    including quantum computation.
                </li>
                <li>
                    <strong>Transaction Costs:</strong> The fees associated with blockchain transactions, such as petrol fees, are unpredictable and have
                    the potential to increase.
                </li>
                <li>
                    <strong>Vulnerabilities of the Service:</strong> The Services may contain flaws or errors. You are accountable for evaluating any
                    code or content that pertains to the Services.
                </li>
                <li>
                    <strong>Accuracy of Information:</strong> The Site's data may not always be precise or current. You assume the risk of making decisions
                    based on this information.
                </li>
                <li>
                    <strong>Financial Risks:</strong> Digital Assets and Contracts transactions are speculative and involve substantial risk. It is
                    possible that the entire value of your assets is lost.
                </li>
                <li>
                    <strong>Limitation of Liability:</strong> We are not responsible for any losses, damages, or claims that may result from the actions
                    of third parties, technical malfunctions, unauthorized access, or user errors.
                </li>
            </ol>

            <p>
                You consent to the assumption of all risks associated with your use of the Services and release us from any related claims or liabilities.
            </p>

            <h3 className="font-bold mt-6">13. Indemnification</h3>
            <p>
                You consent to indemnify, defend, and hold harmless the Company, Chainflow LTD, and their affiliates, as well as their respective shareholders,
                directors, officers, employees, agents, and representatives ("Indemnified Parties"), from any claims, damages, losses, liabilities, costs,
                and expenses (including reasonable attorneys' fees) that arise out of or are related to:
            </p>

            <ul className="list-disc ml-6">
                <li>Your use or misuse of the Site or Services.</li>
                <li>Digital assets that are linked to your wallet address.</li>
                <li>Any content or feedback you submit.</li>
                <li>Your breach of these Terms.</li>
                <li>The infringement or transgression of any third-party rights by you.</li>
            </ul>

            <p>
                You undertake to fully cooperate with us in asserting any available defenses, and we reserve the right to assume the exclusive defense
                and control of any matter subject to indemnification.
            </p>

            <h3 className="font-bold mt-6">14. Disclaimers and Disclosures</h3>

            <ul className="list-disc ml-6">
                <li>
                    <strong>No Financial Services Provided:</strong> Chainflow LTD is a software developer, and the Company licenses this software.
                    Neither entity offers trade execution or clearing services, nor does it operate a digital asset exchange.
                </li>
                <li>
                    <strong>Regulatory Status:</strong> Chainflow LTD and the Company are not registered or licensed with any financial regulatory
                    authority. Financial advice or recommendations are not provided by the Site or software.
                </li>
                <li>
                    <strong>Control Over Protocols:</strong> The Supported Contracts and underlying blockchain protocols are not owned or controlled
                    by the Company or Chainflow LTD. They are not accountable for the security, availability, or efficacy of these protocols.
                </li>
                <li>
                    <strong>"As Is" Provision:</strong> The Site and Services are provided "AS IS" and "AS AVAILABLE" without any type of warranty,
                    whether express or implied.
                </li>
                <li>
                    <strong>No Warranty of Accuracy:</strong> We do not guarantee the accuracy, completeness, or utility of any information that is included.
                </li>
                <li>
                    <strong>Limitation of Liability:</strong> We are not responsible for any indirect, incidental, special, consequential, or exemplary
                    damages that may result from your use of the Site or Services.
                </li>
            </ul>

            <h3 className="font-bold mt-6">15. Liability Restrictions</h3>
            <p>To the fullest extent allowed by law:</p>

            <ul className="list-disc ml-6">
                <li>
                    <strong>Indirect Damages Exclusion:</strong> The Company and its service providers are not responsible for any indirect, incidental,
                    special, consequential, or punitive damages, including loss of profits, revenue, data, or use.
                </li>
                <li>
                    <strong>Liability Cap:</strong> The Company's aggregate liability for any claims that arise under these Terms or related to the
                    use of the Services will not exceed the lesser of USD $50 or the total fees paid by you to the Company in the twelve months
                    prior to the claim.
                </li>
            </ul>

            <h3 className="font-bold mt-6">16. Arbitration and Dispute Resolution</h3>

            <h4 className="font-semibold mt-4">1. Mandatory Arbitration</h4>
            <p>
                All disputes that arise from or are related to these Terms or the Services ("Disputes") shall be resolved exclusively through binding individual
                arbitration under the laws of Cyprus. The right to a trial by jury or to participate in a class action lawsuit is waived by both you and the Company.
            </p>

            <h4 className="font-semibold mt-4">2. Exemptions from Arbitration</h4>
            <p>Either entity has the option to:</p>
            <ul className="list-disc ml-6">
                <li>
                    <strong>Small Claims Court:</strong> If the dispute is eligible under the relevant law, it is recommended that the dispute be resolved in small claims court.
                </li>
                <li>
                    <strong>Injunctive Relief:</strong> Pursue injunctive relief in a court of competent jurisdiction to prevent the infringement or other misuse of intellectual property rights.
                </li>
            </ul>

            <h4 className="font-semibold mt-4">3. Arbitration Procedures</h4>
            <ul className="list-disc ml-6">
                <li>
                    <strong>Rules That Apply:</strong> JAMS will administer arbitration in accordance with its Comprehensive Arbitration Rules and Procedures.
                </li>
                <li>
                    <strong>Initiation of Arbitration:</strong> In order to initiate arbitration, a party must submit a written Demand for Arbitration to JAMS and notify the other party.
                </li>
                <li>
                    <strong>Arbitrator's Authority:</strong> The arbitrator is granted exclusive authority to resolve any disputes regarding the interpretation, applicability, or enforceability of this arbitration agreement.
                </li>
            </ul>

            <h4 className="font-semibold mt-4">4. Costs of Arbitration</h4>
            <ul className="list-disc ml-6">
                <li>
                    <strong>Fee Allocation:</strong> JAMS Rules will regulate the payment of filing, administration, and arbitrator fees.
                </li>
                <li>
                    <strong>Attorneys' Fees:</strong> We will not pursue attorneys' fees from you if we win. If you are successful, you may be entitled to reasonable solicitors' fees in accordance with the relevant laws.
                </li>
            </ul>

            <h4 className="font-semibold mt-4">5. Restrictions on Injunctive Relief</h4>
            <p>Injunctive relief may be granted by the arbitrator solely on an individual basis and only to the extent required to resolve your specific claim.</p>

            <h4 className="font-semibold mt-4">6. Class Action Waiver</h4>
            <p>
                The Company and you have reached an agreement that all claims must be filed in an individual capacity, rather than as a plaintiff or class member
                in any purported class or representative proceeding.
            </p>

            <h4 className="font-semibold mt-4">7. Arbitration Provisions' Severability</h4>
            <p>
                The arbitration agreement shall remain in effect unless any portion of it is determined to be unenforceable. However, the class action waiver
                shall be rendered null and void if it is determined to be unenforceable.
            </p>

            <h3 className="font-bold mt-6">17. General Requirements</h3>

            <h4 className="font-semibold mt-4">1. Privacy Policy</h4>
            <p>
                We kindly request that you review our Privacy Policy, which delineates the methods by which we collect, use, and disclose your personal information.
            </p>

            <h4 className="font-semibold mt-4">2. Electronic Communications</h4>
            <p>
                You agree to receive all communications electronically. We may distribute communications by posting them on the Site or sending them to the email address you have provided.
            </p>

            <h4 className="font-semibold mt-4">3. Cumulative Remedies</h4>
            <p>
                Our rights and remedies under these Terms are cumulative and are in addition to any other rights that may be applicable at law or in equity.
            </p>

            <h4 className="font-semibold mt-4">4. Severability</h4>
            <p>
                If any provision of these Terms is determined to be invalid or unenforceable, the remaining provisions will continue to be in full force and effect.
            </p>

            <h4 className="font-semibold mt-4">5. Force Majeure</h4>
            <p>
                We are not responsible for any delays or performance failings that are the result of events that are beyond our reasonable control, such as natural disasters, war, pandemics, or technical malfunctions.
            </p>

            <h4 className="font-semibold mt-4">6. Assignment</h4>
            <p>
                Without our prior written consent, you are prohibited from assigning or transferring any rights or obligations under these Terms. Assignment of our rights and obligations is permissible without limitation.
            </p>

            <h4 className="font-semibold mt-4">7. Jurisdiction and Governing Law</h4>
            <p>
                The laws of Cyprus, without regard to its conflict of law principles, regulate and interpret these Terms. Any disputes that are not subject to arbitration will be resolved exclusively in the courts of Cyprus.
            </p>

            <h4 className="font-semibold mt-4">8. Headings</h4>
            <p>
                Section headings are provided solely for the sake of convenience and do not influence the interpretation of these Terms.
            </p>

            <h4 className="font-semibold mt-4">9. Entire Agreement</h4>
            <p>
                These Terms are the sole agreement between the Company and you with respect to the use of the Site and Services, and they supersede all previous agreements and understandings.
            </p>

            <h4 className="font-semibold mt-4">10. Interpretation</h4>
            <p>
                Unless the other agreement explicitly specifies otherwise, these Terms shall prevail in the event of a conflict between these Terms and any other agreement with us.
            </p>

            <h4 className="font-semibold mt-4">11. No Third-Party Beneficiaries</h4>
            <p>
                These Terms do not grant any rights or remedies to third parties, except as explicitly stated.
            </p>

            <h4 className="font-semibold mt-4">12. Reservation of Rights</h4>
            <p>
                We retain all rights that are not explicitly granted to you in these Terms. You consent to refrain from obscuring, removing, or modifying any proprietary notices that are displayed on the Site or through the Services.
            </p>

            <p className="mt-6">
                Before utilizing the Site or Services offered by Chainflow Ltd., please verify that you have a comprehensive understanding of and consent to these Terms. Should you have any inquiries or require additional elucidation, please do not hesitate to reach out to us at <a href="mailto:support@paradyze.io" className="text-blue-500 hover:underline">support@paradyze.io</a>.
            </p>


        </div>
    )
}

export default Terms