import {
    ChainGrpcWasmApi,
    ChainGrpcBankApi,
    IndexerRestExplorerApi,
    IndexerGrpcAccountPortfolioApi,
    ChainGrpcTokenFactoryApi,
    IndexerGrpcSpotApi,
    getEthereumAddress,
    getSpotMarketTensMultiplier,
    spotPriceToChainPriceToFixed,
    spotQuantityToChainQuantityToFixed,
    MsgCreateSpotMarketOrder,
} from "@injectivelabs/sdk-ts";

interface EndpointConfig {
    rpc(rpc: string): unknown;
    grpc: string;
    explorer: string;
    indexer: string;
}

class SpotMarketAPI {
    endpoints: EndpointConfig;
    RPC: string;
    chainGrpcWasmApi: ChainGrpcWasmApi;
    chainGrpcBankApi: ChainGrpcBankApi;
    indexerRestExplorerApi: IndexerRestExplorerApi;
    chainGrpcTokenFactoryApi: ChainGrpcTokenFactoryApi;
    indexerGrpcAccountPortfolioApi: IndexerGrpcAccountPortfolioApi;
    indexerGrpcSpotApi: IndexerGrpcSpotApi;

    constructor(endpoints: EndpointConfig) {
        this.endpoints = endpoints;
        this.RPC = endpoints.grpc;

        this.chainGrpcWasmApi = new ChainGrpcWasmApi(this.RPC);
        this.chainGrpcBankApi = new ChainGrpcBankApi(this.RPC);
        this.indexerRestExplorerApi = new IndexerRestExplorerApi(
            this.endpoints.explorer
        );
        this.chainGrpcTokenFactoryApi = new ChainGrpcTokenFactoryApi(this.RPC)

        this.indexerGrpcAccountPortfolioApi =
            new IndexerGrpcAccountPortfolioApi(endpoints.indexer);

        this.indexerGrpcSpotApi = new IndexerGrpcSpotApi(endpoints.indexer)
    }


    async getBalanceOfToken(denom: string, wallet: string) {
        return await this.chainGrpcBankApi.fetchBalance({
            accountAddress: wallet,
            denom,
        });
    }


    async getHelixMarketQuote(marketId, baseTokenAmount, decimals) {
        const market = await this.indexerGrpcSpotApi.fetchMarket(marketId);

        const baseDecimals = decimals;

        const orders = await this.indexerGrpcSpotApi.fetchOrders({ marketId });

        const takerFeeRate = parseFloat(market.takerFeeRate);
        const makerFeeRate = parseFloat(market.makerFeeRate);

        const buyOrders = orders.orders.filter(order => order.orderSide === 'buy').sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        const sellOrders = orders.orders.filter(order => order.orderSide === 'sell').sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

        function calculateQuoteAmount(orderList, baseTokenAmount, fee) {
            let totalPrice = 0;
            let totalQuantity = 0;
            let remainingBaseAmount = baseTokenAmount

            for (const order of orderList) {
                let price = parseFloat(order.price) / Math.pow(10, 18 - baseDecimals);
                const quantity = parseFloat(order.unfilledQuantity) / Math.pow(10, baseDecimals);

                // console.log(price, quantity)

                price += price * fee;

                if (remainingBaseAmount <= quantity) {
                    totalPrice += remainingBaseAmount * price;
                    totalQuantity += remainingBaseAmount;
                    break;
                } else {
                    totalPrice += quantity * price;
                    totalQuantity += quantity;
                    remainingBaseAmount -= quantity;
                }
            }

            return {
                averagePrice: totalQuantity ? totalPrice / totalQuantity : 0,
                quoteAmount: totalPrice
            };
        }

        const { averagePrice: averageBuyPrice, quoteAmount: buyQuoteAmount } = calculateQuoteAmount(sellOrders, baseTokenAmount, takerFeeRate);
        // console.log("")
        const { averagePrice: averageSellPrice, quoteAmount: sellQuoteAmount } = calculateQuoteAmount(buyOrders, baseTokenAmount, -takerFeeRate);

        return {
            averageBuyPrice,
            averageSellPrice,
            buyQuoteAmount,
            sellQuoteAmount
        };
    }

    async constructSpotMarketOrder(marketId, price, quantity, orderType, baseDecimals, quoteDecimals, feeRecipient) {
        const marketInfo = await this.indexerGrpcSpotApi.fetchMarket(marketId);
        const injectiveAddress = feeRecipient

        const { priceTensMultiplier, quantityTensMultiplier } = getSpotMarketTensMultiplier({
            baseDecimals: baseDecimals,
            quoteDecimals: quoteDecimals,
            minPriceTickSize: marketInfo.minPriceTickSize,
            minQuantityTickSize: marketInfo.minQuantityTickSize,
        })

        const market = {
            marketId: marketId,
            baseDecimals: baseDecimals,
            quoteDecimals: quoteDecimals,
            minPriceTickSize: marketInfo.minPriceTickSize,
            minQuantityTickSize: marketInfo.minQuantityTickSize,
            priceTensMultiplier: priceTensMultiplier,
            quantityTensMultiplier: quantityTensMultiplier,
        }

        const order = {
            price: price,
            quantity: quantity
        }

        const ethereumAddress = getEthereumAddress(injectiveAddress)
        const subaccountIndex = 0
        const suffix = '0'.repeat(23) + subaccountIndex
        const subaccountId = ethereumAddress + suffix

        const msg = MsgCreateSpotMarketOrder.fromJSON({
            subaccountId,
            injectiveAddress,
            orderType: orderType,
            price: spotPriceToChainPriceToFixed({
                value: order.price,
                tensMultiplier: market.priceTensMultiplier,
                baseDecimals: market.baseDecimals,
                quoteDecimals: market.quoteDecimals
            }),
            quantity: spotQuantityToChainQuantityToFixed({
                value: order.quantity,
                tensMultiplier: market.quantityTensMultiplier,
                baseDecimals: market.baseDecimals
            }),
            marketId: market.marketId,
            feeRecipient: feeRecipient,
        })

        return msg
    }

}


export default SpotMarketAPI;
