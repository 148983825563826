import {
    ChainGrpcWasmApi,
    ChainGrpcBankApi,
    IndexerRestExplorerApi,
    IndexerGrpcAccountPortfolioApi,
    ChainGrpcTokenFactoryApi,
    IndexerGrpcSpotApi,
    IndexerGrpcDerivativesApi,
    IndexerGrpcDerivativesStream,
} from "@injectivelabs/sdk-ts";

interface EndpointConfig {
    rpc(rpc: string): unknown;
    grpc: string;
    explorer: string;
    indexer: string;
}

class BinaryOptionMarketAPI {
    endpoints: EndpointConfig;
    RPC: string;
    chainGrpcWasmApi: ChainGrpcWasmApi;
    chainGrpcBankApi: ChainGrpcBankApi;
    indexerRestExplorerApi: IndexerRestExplorerApi;
    chainGrpcTokenFactoryApi: ChainGrpcTokenFactoryApi;
    indexerGrpcAccountPortfolioApi: IndexerGrpcAccountPortfolioApi;
    indexerGrpcSpotApi: IndexerGrpcSpotApi;

    constructor(endpoints: EndpointConfig) {
        this.endpoints = endpoints;
        this.RPC = endpoints.grpc;

        this.chainGrpcWasmApi = new ChainGrpcWasmApi(this.RPC);
        this.chainGrpcBankApi = new ChainGrpcBankApi(this.RPC);
        this.indexerRestExplorerApi = new IndexerRestExplorerApi(
            this.endpoints.explorer
        );
        this.chainGrpcTokenFactoryApi = new ChainGrpcTokenFactoryApi(this.RPC)

        this.indexerGrpcAccountPortfolioApi =
            new IndexerGrpcAccountPortfolioApi(endpoints.indexer);

        this.indexerGrpcSpotApi = new IndexerGrpcSpotApi(endpoints.indexer)
    }

    async fetchBinaryOptionMarkets(status: string) {
        const marketsAPI = new IndexerGrpcDerivativesApi(this.endpoints.indexer)
        const markets = await marketsAPI.fetchBinaryOptionsMarkets({
            marketStatus: status,
            pagination: { limit: 20 }
        })
        return markets.markets
    }

    async fetchBinaryOptionMarket(marketId: string) {
        const marketsAPI = new IndexerGrpcDerivativesApi(this.endpoints.indexer)
        const market = await marketsAPI.fetchBinaryOptionsMarket(marketId)
        return market
    }

    async getBalanceOfToken(denom: string, wallet: string) {
        return await this.chainGrpcBankApi.fetchBalance({
            accountAddress: wallet,
            denom,
        });
    }

    async getDenomMetadata(denom: string) {
        const token = await this.chainGrpcBankApi.fetchDenomMetadata(denom)
        return token
    }

    async getDerivativeMarketOrders(marketId: string, subAccount = null) {
        const marketsAPI = new IndexerGrpcDerivativesApi(this.endpoints.indexer);
        const limit = 100;
        let skip = 0;
        let allOrders = [];
        let hasMoreOrders = true;

        while (hasMoreOrders) {
            const ordersResponse = await marketsAPI.fetchOrderHistory({
                marketId: marketId,
                subaccountId: subAccount,
                pagination: {
                    limit: limit,
                    skip: skip
                }
            });

            const fetchedOrders = ordersResponse.orderHistory;

            if (fetchedOrders.length === 0) {
                hasMoreOrders = false;
            } else {
                allOrders = allOrders.concat(fetchedOrders);
                skip += limit;
            }
        }
        return allOrders;
    }

    async getDerivativeMarketTrades(marketId: string, subAccount = null) {
        const marketsAPI = new IndexerGrpcDerivativesApi(this.endpoints.indexer);
        const limit = 100;
        let skip = 0;
        let allTrades = [];
        let hasMoreOrders = true;

        while (hasMoreOrders) {
            const ordersResponse = await marketsAPI.fetchTrades({
                marketId: marketId,
                subaccountId: subAccount,
                pagination: {
                    limit: limit,
                    skip: skip
                }
            });

            const fetchedOrders = ordersResponse.trades;

            if (fetchedOrders.length === 0) {
                hasMoreOrders = false;
            } else {
                allTrades = allTrades.concat(fetchedOrders);
                skip += limit;
            }
        }
        return allTrades;
    }

    async streamOrderBook(marketId: string, callback) {
        const indexerGrpcDerivativesStream = new IndexerGrpcDerivativesStream(
            this.endpoints.indexer,
        )

        const streamFn = indexerGrpcDerivativesStream.streamDerivativeOrders.bind(
            indexerGrpcDerivativesStream,
        )

        const streamFnArgs = {
            marketId,
            callback,
        }

        console.log("start stream")

        return streamFn(streamFnArgs)
    }

}


export default BinaryOptionMarketAPI;
