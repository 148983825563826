import { useDispatch, useSelector } from "react-redux";
import { closeWalletConnectModal, openErrorModal } from "../../redux/reducers/modal";
import leapLogo from "../../assets/leap.png"
import keplrLogo from "../../assets/keplr.png"
import { FaChevronRight } from "react-icons/fa";
import { setConnectedAddress, setConnectedWallet } from "../../redux/reducers/wallet";
import { useCallback, useEffect } from "react";

export default function SelectWalletModal() {
    const isOpen = useSelector((state) => state.modals.isWalletConnectOpen);
    const dispatch = useDispatch();

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);

    const getWallet = useCallback((wallet) => {
        if (wallet == "leap") {
            if (!window.leap) {
                throw new Error('Leap extension not installed')
            }
            return window.leap
        }
        if (wallet == "keplr") {
            if (!window.keplr) {
                throw new Error('Keplr extension not installed')
            }
            return window.keplr
        }
        return window.keplr
    }, [])

    const suggestTestnet = useCallback(async (walletId) => {
        const wallet = getWallet(walletId);
        await wallet.experimentalSuggestChain({
            chainId: "injective-888",
            chainName: "Injective Testnet",
            rpc: "https://k8s.testnet.tm.injective.network:443",
            rest: "https://k8s.testnet.lcd.injective.network:443",
            bip44: {
                coinType: 118,
            },
            bech32Config: {
                bech32PrefixAccAddr: "inj",
                bech32PrefixAccPub: "injpub",
                bech32PrefixValAddr: "injvaloper",
                bech32PrefixValPub: "injvaloperpub",
                bech32PrefixConsAddr: "injvalcons",
                bech32PrefixConsPub: "injvalconspub",
            },
            currencies: [
                {
                    coinDenom: "INJ",
                    coinMinimalDenom: "inj",
                    coinDecimals: 18,
                    coinGeckoId: "injective-protocol",
                },
            ],
            feeCurrencies: [
                {
                    coinDenom: "INJ",
                    coinMinimalDenom: "inj",
                    coinDecimals: 18,
                    coinGeckoId: "injective-protocol",
                    gasPriceStep: {
                        low: 0.0005,
                        average: 0.0008,
                        high: 0.001,
                    },
                },
            ],
            stakeCurrency: {
                coinDenom: "INJ",
                coinMinimalDenom: "inj",
                coinDecimals: 18,
                coinGeckoId: "injective-protocol",
            },
        });
    }, [getWallet])

    const loadWalletAddress = useCallback(async (walletId) => {
        try {
            const wallet = getWallet(walletId);
            const chainId = import.meta.env.VITE_CHAIN_ID
            await wallet.enable(chainId);
            const injectiveAddresses = await wallet.getOfflineSigner(chainId).getAccounts();
            dispatch(setConnectedAddress(
                injectiveAddresses[0].address
            ));
            dispatch(setConnectedWallet(walletId))
        }
        catch (e) {
            if (e.message == "Keplr extension not installed") {
                dispatch(openErrorModal("Keplr Wallet not detected"))
            }
            if (e.message == "There is no modular chain info for injective-888") {
                await suggestTestnet(walletId)
                loadWalletAddress(walletId)
            }
        }
    }, [dispatch, getWallet, suggestTestnet])

    const connectKeplr = () => {
        loadWalletAddress("keplr")
    }

    const connectLeap = () => {
        loadWalletAddress("leap")
    }

    useEffect(() => {
        if (connectedAddress) {
            dispatch(closeWalletConnectModal())
        }
    }, [connectedAddress, dispatch])

    return (
        <div
            className="font-aileron font-regular text-white"
        >
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg min-w-[400px] p-6 rounded-lg shadow-lg mx-5 z-50">
                        <div className="my-5 font-bold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                            Connect Wallet
                        </div>
                        <div
                            onClick={connectKeplr}
                            className="flex flex-row items-center bg-gray-800 p-3 rounded-lg border border-gold-gradient-start hover:cursor-pointer"
                        >
                            <div>
                                <img src={keplrLogo} height={50} width={50} />
                            </div>
                            <div className="ml-5">
                                Keplr Wallet
                            </div>
                            <FaChevronRight className="ml-auto" />
                        </div>
                        <div
                            onClick={connectLeap}
                            className="flex flex-row items-center mt-2 bg-gray-800 p-3 rounded-lg border border-gold-gradient-start hover:cursor-pointer"
                        >
                            <div>
                                <img src={leapLogo} height={50} width={50} className="rounded-lg" />
                            </div>
                            <div className="ml-5">
                                Leap Wallet
                            </div>
                            <FaChevronRight className="ml-auto" />
                        </div>
                        <div
                            className="mt-5 hover:cursor-pointer hover:underline"
                            onClick={() => dispatch(closeWalletConnectModal())}>
                            Cancel
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
