import { useDispatch, useSelector } from "react-redux";
import { closeErrorModal } from "../../redux/reducers/modal";

export default function ErrorModal() {
    const isOpen = useSelector((state) => state.modals.isErrorModalOpen);
    const message = useSelector((state) => state.modals.errorMessage);

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeErrorModal())
    };

    return (
        <div
            className="font-aileron font-regular"
        >
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg p-6 rounded-lg shadow-lg mx-5">
                        <div className="my-5">
                            {message}
                        </div>
                        <button
                            onClick={closeModal}
                            className={`w-full px-4 py-2 rounded-lg font-semibold bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black}`}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
