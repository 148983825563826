import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { NETWORKS } from "../../utils/constants";
import { IndexerGrpcAccountApi } from "@injectivelabs/sdk-ts";
import { calculateNetFilledQuantityAndAveragePrice, hexToBech32, humanReadableAmount } from "../../utils/functions";


const TradeHistoryTable = (props) => {

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const explorerUrl = import.meta.env.VITE_INJ_EXPLORER_URL

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const [subAccount, setSubAccount] = useState(null)

    const [totalShares, setTotalMargin] = useState(0)
    const [avgPrice, setAvgPrice] = useState(0)

    const [onChainMarket, setOnChainMarket] = useState(null)
    const [marketSettled, setMarketSettled] = useState(false)

    const [trades, setTrades] = useState([])

    const getSubAccount = useCallback(async (injectiveAddress: string) => {
        const indexerGrpcAccountApi = new IndexerGrpcAccountApi(networkConfig.indexer)
        const subaccountsList = await indexerGrpcAccountApi.fetchSubaccountsList(
            injectiveAddress,
        )
        setSubAccount(subaccountsList[0])
        return subaccountsList
    }, [networkConfig])

    useEffect(() => {
        setOnChainMarket(props.onChainMarket)
        setTrades(props.trades.sort((a, b) => b.executedAt - a.executedAt))
    }, [props.onChainMarket, props.trades])

    useEffect(() => {
        if (trades) {
            const totalShares = calculateNetFilledQuantityAndAveragePrice(trades)
            setTotalMargin(totalShares.totalFilledQuantity)
            setAvgPrice(totalShares.weightedAveragePrice)
        }
    }, [trades])

    useEffect(() => {
        if (onChainMarket) {
            setMarketSettled(moment.unix(onChainMarket.settlementTimestamp).isBefore(moment()))
        }
    }, [onChainMarket])

    useEffect(() => {
        if (!connectedAddress) {
            setSubAccount(null)
        }
        else {
            getSubAccount(connectedAddress)
        }
    }, [connectedAddress, getSubAccount])


    return (
        <>
            <div className="flex flex-row justify-between mt-2">
                {/* {avgPrice !== 0 &&
                    <div
                        className="text-left text-sm"
                    >
                        {(avgPrice * 100).toFixed(2)}% YES
                        <br />
                        {((1 - avgPrice) * 100).toFixed(2)}% NO
                    </div>
                } */}
                {totalShares !== 0 && props.showVolume &&
                    <div
                        className="text-right text-sm ml-auto mb-2"
                    >
                        Volume: {humanReadableAmount(totalShares)} USDT
                    </div>
                }
            </div>
            <div className="flex justify-center items-center">
                <div className="w-full">
                    {trades.length == 0 &&
                        <div className="text-center mt-5 text-sm">
                            No trades yet...
                        </div>
                    }
                    <div className="flex flex-row">
                        {trades.length > 0 &&
                            <div className="overflow-x-auto mt-1 text-sm  w-full max-h-80 overflow-y-scroll">
                                <table className="table-auto w-full">
                                    <thead className="text-left">
                                        <tr>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Time
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                User
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Side
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end" >
                                                Type
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Price
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Shares
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Margin
                                            </th>
                                            {marketSettled &&
                                                <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                    PnL
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm">
                                        {trades.filter(order => order.tradeExecutionType !== "expiryMarketSettlement").map((trade, index) => (
                                            <tr
                                                key={index}
                                                className="text-white border-b text-left"
                                            >
                                                <td className="py-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {moment(trade.executedAt).format("YYYY-MM-DD HH:mm:ss")}
                                                </td>
                                                <td className="py-1 px-2">
                                                    <a href={`${explorerUrl}account/${hexToBech32(trade.subaccountId.slice(0, 42))}`}>
                                                        {subAccount !== null && trade.subaccountId == subAccount ?
                                                            <div className="p-1 bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black rounded text-center w-8 ">You</div>
                                                            :
                                                            `${hexToBech32(trade.subaccountId.slice(0, 42)).slice(0, 5)}...${hexToBech32(trade.subaccountId.slice(0, 42)).slice(-5)}`}
                                                    </a>
                                                </td>
                                                <td className="py-1 px-2">
                                                    {trade.tradeDirection == "buy" ?
                                                        <div
                                                            className="text-emerald-500"
                                                        >
                                                            YES
                                                        </div> :
                                                        <div
                                                            className="text-rose-500"
                                                        >
                                                            NO
                                                        </div>
                                                    }
                                                </td>
                                                <td className="py-1 px-2 capitalize">
                                                    {trade.executionSide}
                                                </td>
                                                <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {(
                                                        trade.direction === "sell"
                                                            ? 1 - Number(trade.executionPrice) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                            : Number(trade.executionPrice) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                    ).toFixed(4)}{" "}
                                                    {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                                </td>
                                                <td className="py-1 px-2">
                                                    {trade.executionQuantity}
                                                </td>
                                                <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {Number(trade.executionMargin) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)}  {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                                </td>

                                                {marketSettled && onChainMarket &&
                                                    <td className="py-1 px-2">
                                                        {onChainMarket.settlementPrice < 0 ?
                                                            <div>
                                                                refunded
                                                            </div>
                                                            :
                                                            <div>
                                                                {onChainMarket.settlementPrice > 0 ?
                                                                    <div>
                                                                        {trade.tradeDirection === "sell" ?
                                                                            <div className="text-rose-500">
                                                                                -{(trade.executionMargin / Math.pow(10, 6)).toFixed(2)} USDT
                                                                            </div>
                                                                            :
                                                                            <div className="text-emerald-500">
                                                                                +{(trade.executionQuantity - (trade.executionMargin / Math.pow(10, 6))).toFixed(2)} USDT
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {trade.tradeDirection === "buy" ?
                                                                            <div className="text-rose-500">
                                                                                -{(trade.executionMargin / Math.pow(10, 6)).toFixed(2)} USDT
                                                                            </div>
                                                                            :
                                                                            <div className="text-emerald-500">
                                                                                +{(trade.executionQuantity - (trade.executionMargin / Math.pow(10, 6))).toFixed(2)} USDT
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>

    );
};

export default TradeHistoryTable;
