import { useCallback, useEffect, useState } from "react";
import logo from "../../assets/ParadyzeLogowText.webp";
import BinaryOptionMarketAPI from "../../modules/BinaryOptionMarketAPI";
import { NETWORKS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeTokenAccessModal, openTokenAccessModal } from "../../redux/reducers/modal";
import WalletConnect from "../WalletConnect";

export default function TokenAccessModal() {
    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const { isTokenAccessModalOpen: isOpen, isTermsModalOpen: termsModalOpen } = useSelector(state => state.modals);
    const dispatch = useDispatch();

    const amountRequired = Number(import.meta.env.VITE_TEST_NET_ACCESS_DENOM_AMOUNT);
    const decimals = Number(import.meta.env.VITE_TEST_NET_ACCESS_DENOM_DECIMALS);
    const tokenGateEnabled = import.meta.env.VITE_TOKEN_GATED_ACCESS === 'true';
    const denom = import.meta.env.VITE_TEST_NET_ACCESS_DENOM;

    const [tokenMeta, setTokenMeta] = useState(null);
    const [tokenBalance, setTokenBalance] = useState(0);

    const checkTokenBalance = useCallback(async () => {
        if (!connectedAddress) {
            dispatch(closeTokenAccessModal());
            return
        };

        const module = new BinaryOptionMarketAPI(NETWORKS['injective-1']);
        const balance = await module.getBalanceOfToken(denom, connectedAddress);
        const meta = await module.getDenomMetadata(denom);
        setTokenMeta(meta);

        const hasSufficientBalance = Number(balance['amount']) >= Number(amountRequired);

        if (tokenGateEnabled && !hasSufficientBalance && !termsModalOpen) {
            setTokenBalance(Number(balance['amount']));
            dispatch(openTokenAccessModal());
        } else {
            dispatch(closeTokenAccessModal());
        }
    }, [amountRequired, connectedAddress, denom, dispatch, termsModalOpen, tokenGateEnabled]);

    useEffect(() => {
        if (connectedAddress) {
            checkTokenBalance();
        }
        else {
            dispatch(closeTokenAccessModal())
        }
    }, [checkTokenBalance, termsModalOpen, connectedAddress, dispatch]);

    return (
        <div className="font-aileron font-regular">
            {isOpen && tokenMeta && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg p-6 rounded-lg shadow-lg mx-5 text-center">
                        <img className="mx-auto" src={logo} width={150} alt="logo" />
                        <p>This testnet is gated to users who own the {tokenMeta.symbol} token</p>
                        <div className="mt-5">
                            <p>Required balance: {amountRequired / Math.pow(10, decimals)}</p>
                            <p>Your balance: {tokenBalance / Math.pow(10, decimals)}</p>
                        </div>
                        <div className="flex justify-center mt-5">
                            <WalletConnect />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
