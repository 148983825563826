import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BinaryOptionMarketAPI from '../../modules/BinaryOptionMarketAPI';
import { NETWORKS } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { IndexerGrpcAccountApi, MsgCreateBinaryOptionsLimitOrder, MsgCreateBinaryOptionsMarketOrder } from '@injectivelabs/sdk-ts';
import { InjectiveExchangeV1Beta1Exchange } from '@injectivelabs/core-proto-ts';
import { Buffer } from "buffer";
import { getOfflineSigner, handleSendTx } from '../../utils/transaction';
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { Slider } from "@/components/ui/slider"

const FEE_WALLET = import.meta.env.VITE_FEE_WALLET;


const LIGHT_GOLD = "#f0e68c"
const DARK_GOLD = "#9d7c36"

const OrderPanel = (props) => {
    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);
    const connectedAddress = useSelector(state => state.wallet.connectedAddress);

    const [searchParams, setSearchParams] = useSearchParams();
    const side = searchParams.get('side');

    const [marketDetails, setMarketDetails] = useState(null)
    const [onChainMarket, setOnChainMarket] = useState(null)

    const [orders, setOrders] = useState([])

    const [rawCost, setRawCost] = useState(10);

    const [quantity, setQuantity] = useState(10);
    const [price, setPrice] = useState(0);
    const [initLoad, setInitLoad] = useState(false);

    const [orderType, setOrderType] = useState('limit');
    const [error, setError] = useState(null)
    const [executionPrice, setExecutionPrice] = useState(0)
    const [quoteBalance, setQuoteBalance] = useState(null)

    const [confirmTrade, setConfirmTrade] = useState(false);

    useEffect(() => {
        setQuoteBalance(null)
    }, [connectedAddress])

    const getQuoteBalance = useCallback(async () => {
        const module = new BinaryOptionMarketAPI(networkConfig)
        const balance = await module.getBalanceOfToken(onChainMarket.quoteDenom, connectedAddress)
        setQuoteBalance(Number(balance.amount) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6))
    }, [networkConfig, connectedAddress, onChainMarket])

    useEffect(() => {
        if (!quoteBalance && onChainMarket && connectedAddress) {
            void getQuoteBalance().then().catch()
        }
    }, [getQuoteBalance, quoteBalance, onChainMarket, connectedAddress])

    useEffect(() => {
        setMarketDetails(props.marketDetails)
        setOnChainMarket(props.onChainMarket)
        setOrders(props.orders.filter(order => order.isActive == true))
    }, [props.onChainMarket, props.orders, props.marketDetails])

    useEffect(() => {
        if (price < 0) {
            console.log(price)
            setPrice(0.01)
        }
        else if (price > 100) {
            setPrice(99.9)
        }
    }, [price])

    useEffect(() => {
        setQuantity((rawCost / (price / 100)).toFixed(2))
    }, [rawCost, price])

    useEffect(() => {
        if (orderType === "market") {
            const calculateExecutionPrice = (marketSide, marketQuantity) => {
                const opposingOrders = orders
                    .filter(order => order.direction !== marketSide && order.executionType === "limit")
                    .sort((a, b) =>
                        marketSide === "buy" ? a.price - b.price : b.price - a.price
                    );

                let totalQuantityFilled = 0;
                let totalCost = 0;

                for (const order of opposingOrders) {
                    const availableQuantity = parseFloat(order.quantity) - parseFloat(order.filledQuantity);
                    console.log(availableQuantity)
                    const price = parseFloat(order.price);
                    if (totalQuantityFilled + availableQuantity >= marketQuantity) {
                        const remainingQuantity = marketQuantity - totalQuantityFilled;
                        totalCost += remainingQuantity * price;
                        totalQuantityFilled += remainingQuantity;
                        break;
                    } else {
                        totalCost += availableQuantity * price;
                        totalQuantityFilled += availableQuantity;
                    }
                }
                if (totalQuantityFilled < marketQuantity) {
                    console.warn("Market order quantity exceeds available liquidity");
                    return null;
                }
                return totalCost / totalQuantityFilled;
            };

            const executionPrice = calculateExecutionPrice(side == "YES" ? "buy" : "sell", parseFloat(quantity));
            if (executionPrice !== null) {
                if (side == "NO") {
                    setExecutionPrice(1 - (executionPrice / Math.pow(10, 6)));
                    setPrice((1 - (executionPrice / Math.pow(10, 6))) * 100)
                }
                else {
                    setExecutionPrice(executionPrice / Math.pow(10, 6));
                    setPrice((executionPrice / Math.pow(10, 6)) * 100);
                }
            }
        }
    }, [orderType, side, orders, quantity]);

    useEffect(() => {
        if (!marketDetails) return
        if (marketDetails.prices.length > 0 && marketDetails.prices[0].price !== 0 && price == 0 && !initLoad) {
            if (side == "YES") {
                setPrice((marketDetails.prices[0].price * 100).toFixed(2))
            }
            else {
                setPrice((100 - (marketDetails.prices[0].price * 100)).toFixed(2))
            }
            setInitLoad(true)
        }
    }, [marketDetails, side, price, initLoad])

    const getSubAccount = useCallback(async (injectiveAddress: string) => {
        const indexerGrpcAccountApi = new IndexerGrpcAccountApi(networkConfig.indexer)
        const subaccountsList = await indexerGrpcAccountApi.fetchSubaccountsList(
            injectiveAddress,
        )
        return subaccountsList
    }, [networkConfig])

    const handleBuy = useCallback(async () => {
        if (!onChainMarket) return
        console.log('Buy', { quantity, price, orderType });
        setError(null)

        if (!connectedAddress) {
            setError("Please connect your wallet")
            return
        }

        const { key, offlineSigner } = await getOfflineSigner(networkConfig.chainId);
        const pubKey = Buffer.from(key.pubKey).toString("base64");
        const injectiveAddress = key.bech32Address;

        if (connectedAddress !== injectiveAddress) {
            setError("Wrong wallet connected")
            return
        }
        else {
            setError(null)
        }

        let subAccountId = await getSubAccount(injectiveAddress)
        subAccountId = subAccountId[0]

        let msgBuy = null

        let decimals = 6
        if (onChainMarket.quoteToken) {
            decimals = onChainMarket.quoteToken.decimals
        }


        if (orderType == "limit") {
            msgBuy = MsgCreateBinaryOptionsLimitOrder.fromJSON({
                marketId: onChainMarket.marketId,
                subaccountId: subAccountId,
                injectiveAddress: injectiveAddress,
                orderType: InjectiveExchangeV1Beta1Exchange.OrderType.BUY,
                triggerPrice: (Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                feeRecipient: FEE_WALLET,
                price: Math.round(Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                margin: Math.round(((Number(quantity) * Number(price / 100)) * Math.pow(10, decimals))).toString(),
                quantity: quantity,
            });
        }
        else if (orderType == "market") {
            msgBuy = MsgCreateBinaryOptionsMarketOrder.fromJSON({
                marketId: onChainMarket.marketId,
                subaccountId: subAccountId,
                injectiveAddress: injectiveAddress,
                orderType: InjectiveExchangeV1Beta1Exchange.OrderType.BUY,
                triggerPrice: (Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                feeRecipient: FEE_WALLET,
                price: Math.round(Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                margin: Math.round(((Number(quantity) * Number(price / 100)) * Math.pow(10, decimals))).toString(),
                quantity: quantity,
            });
        }
        else {
            return
        }

        console.log("buy order", msgBuy)

        const result = await handleSendTx(networkConfig, pubKey, msgBuy, injectiveAddress, offlineSigner)
        if (result) {
            setQuoteBalance(null)
            setConfirmTrade(false)
        }
    }, [quantity, price, orderType, networkConfig, connectedAddress, getSubAccount, onChainMarket,]);

    const handleSell = useCallback(async () => {
        if (!onChainMarket) return
        console.log('Sell', { quantity, price, orderType });
        setError(null)
        const { key, offlineSigner } = await getOfflineSigner(networkConfig.chainId);
        const pubKey = Buffer.from(key.pubKey).toString("base64");
        const injectiveAddress = key.bech32Address;

        if (connectedAddress !== injectiveAddress) {
            setError("Wrong wallet connected")
            return
        }
        else {
            setError(null)
        }

        let subAccountId = await getSubAccount(injectiveAddress)
        subAccountId = subAccountId[0]

        let msgBuy = null

        let decimals = 6
        if (onChainMarket.quoteToken) {
            decimals = onChainMarket.quoteToken.decimals
        }

        if (orderType == "limit") {
            msgBuy = MsgCreateBinaryOptionsLimitOrder.fromJSON({
                marketId: onChainMarket.marketId,
                subaccountId: subAccountId,
                injectiveAddress: injectiveAddress,
                orderType: InjectiveExchangeV1Beta1Exchange.OrderType.SELL,
                triggerPrice: (Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                feeRecipient: FEE_WALLET,
                price: Math.round(Number((100 - price) / 100) * Math.pow(10, Number(decimals))).toString(),
                margin: Math.round((Number(quantity) * (Number(price / 100))) * Math.pow(10, decimals)).toString(),
                quantity: quantity,
            });
        }
        else if (orderType == "market") {
            msgBuy = MsgCreateBinaryOptionsMarketOrder.fromJSON({
                marketId: onChainMarket.marketId,
                subaccountId: subAccountId,
                injectiveAddress: injectiveAddress,
                orderType: InjectiveExchangeV1Beta1Exchange.OrderType.SELL,
                price: Math.round(Number((100 - price) / 100) * Math.pow(10, Number(decimals))).toString(),
                feeRecipient: FEE_WALLET,
                triggerPrice: (Number(price / 100) * Math.pow(10, Number(decimals))).toString(),
                margin: Math.round((Number(quantity) * (Number(price / 100))) * Math.pow(10, decimals)).toString(),
                quantity: quantity,
            });
        }
        else {
            return
        }

        console.log("sell order", msgBuy)
        const result = await handleSendTx(networkConfig, pubKey, msgBuy, injectiveAddress, offlineSigner)
        if (result) {
            setQuoteBalance(null)
            setConfirmTrade(false)
        }
    }, [connectedAddress, getSubAccount, networkConfig, orderType, price, onChainMarket, quantity]);

    const GoldHeader = ({ children, className = "" }: { children: React.ReactNode, className?: string }) => (
        <div className={`text-lg font-semibold  text-center bg-clip-text text-transparent bg-gradient-to-r ${className}`} style={{ backgroundImage: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})` }}>{children}</div>
    )

    const CustomTooltip = ({ children, content }: { children: React.ReactNode, content: React.ReactNode }) => (
        <TooltipPrimitive.Root>
            <TooltipPrimitive.Trigger asChild>
                {children}
            </TooltipPrimitive.Trigger>
            <TooltipPrimitive.Content
                className="bg-black bg-opacity-90 text-white p-2 rounded-md text-sm max-w-xs"
                sideOffset={5}
            >
                {content}
                <TooltipPrimitive.Arrow className="fill-black" />
            </TooltipPrimitive.Content>
        </TooltipPrimitive.Root>
    )

    const openConfirmModal = useCallback(() => {
        setConfirmTrade(true)
    }, [])

    const ConfirmationModal = ({ isOpen, onClose, onConfirm, tradeDetails }) => {
        if (!isOpen || !tradeDetails) return null;

        return (
            <div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
                <div className="bg-black text-gold p-6 rounded-md shadow-lg max-w-sm w-full">
                    <h2 className="text-xl font-bold mb-4">Confirm Trade</h2>
                    <p>Side: {tradeDetails.side}</p>
                    <p>Quantity: {tradeDetails.quantity}</p>
                    <p>Price: ${tradeDetails.price / 100}</p>
                    <p>Order Type: {tradeDetails.orderType}</p>
                    <p>
                        Cost: {((Number(quantity) * Number(price / 100)) * (1 + Number(onChainMarket.makerFeeRate))).toFixed(2)} {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : 'USDT'}
                    </p>
                    {error && <div className='my-2 text-red-500'>{error}</div>}


                    <div className="flex justify-end space-x-4 mt-4">
                        <button
                            onClick={onClose}
                            className="w-full px-4 py-2 text-gold font-bold rounded-md bg-black border border-gold"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            className="w-full text-black font-bold py-3 rounded-lg relative overflow-hidden group"
                            style={{
                                background: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})`,
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            <ConfirmationModal
                isOpen={confirmTrade}
                onClose={() => setConfirmTrade(false)}
                onConfirm={side == "YES" ? handleBuy : handleSell}
                tradeDetails={{
                    side: side,
                    quantity: quantity,
                    price: price,
                    orderType: orderType
                }}
            />
            <TooltipPrimitive.Provider>
                <div
                    className=' bg-black bg-opacity-50 backdrop-blur-sm rounded-lg p-5 shadow-lg text-gold font-regular'
                >
                    <div className="">
                        <div className="flex w-full mb-4 font-semibold">
                            <button
                                className={`flex-1 rounded rounded-r-none h-6 text-sm ${orderType === 'market' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                                    }`}
                                onClick={() => setOrderType('market')}
                            >
                                Market
                            </button>
                            <button
                                className={`flex-1 rounded rounded-l-none h-6 text-sm ${orderType === 'limit' ? 'bg-gradient-to-r from-[#9d7c36] to-[#f0e68c] text-black' : 'bg-transparent text-[#f0e68c] border border-[#f0e68c]'
                                    }`}
                                onClick={() => setOrderType('limit')}
                            >
                                Limit
                            </button>
                        </div>
                        <div className="flex mb-4 font-bold">
                            <button
                                className={`flex-1 rounded-r-none border h-10 text-base rounded ${side === 'YES' ? 'bg-emerald-500 text-white border-emerald-500' :
                                    'bg-transparent text-emerald-500  border-emerald-500'
                                    }`}
                                onClick={() => {
                                    const newParams = new URLSearchParams(searchParams);
                                    newParams.set('side', 'YES');
                                    setSearchParams(newParams);
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className={`flex-1 rounded-l-none border h-10 text-base rounded ${side === 'NO' ? 'bg-rose-500 text-white border-rose-500' :
                                    'bg-transparent text-rose-500 border-rose-500'
                                    }`}
                                onClick={() => {
                                    const newParams = new URLSearchParams(searchParams);
                                    newParams.set('side', 'NO');
                                    setSearchParams(newParams);
                                }}
                            >
                                No
                            </button>
                        </div>
                        <div className="mb-6">
                            <GoldHeader>How much</GoldHeader>
                            <div className='text-sm text-gray-400 mb-2'>How much money do you want to spend?</div>
                            <div className="bg-black bg-opacity-50 p-3 rounded-lg">
                                <input
                                    type="number"
                                    value={rawCost}
                                    onChange={(e) => setRawCost(e.target.value)}
                                    className="bg-transparent border-none text-3xl font-bold text-white p-0 text-center bg-clip-text text-transparent bg-gradient-to-r"
                                    style={{ backgroundImage: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})` }}
                                />
                                <div className="text-sm text-gray-400 text-center">Balance: {Number(quoteBalance).toFixed(2)} USDT</div>
                            </div>
                        </div>
                        {orderType === 'limit' ? (
                            <div className="mb-6">
                                <CustomTooltip content={
                                    <div>
                                        <p>Probability represents the likelihood of the event occurring.</p>
                                        <p>Price is derived from the probability (Price = Probability / 100).</p>
                                    </div>
                                }>
                                    <div>
                                        <GoldHeader>Probability / Price</GoldHeader>
                                        <div className="bg-black bg-opacity-50 p-3 rounded-lg mt-2">
                                            <div className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r" style={{ backgroundImage: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})` }}>{price}%</div>
                                            <div className="text-sm text-gray-400 text-center">{(price / 100).toFixed(2)}$</div>
                                        </div>
                                    </div>
                                </CustomTooltip>
                                <div className="mt-4 relative">
                                    <div className="absolute inset-0 bg-gray-200 rounded-full"></div>
                                    <div
                                        className="absolute inset-0 rounded-full"
                                        style={{
                                            background: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})`,
                                            width: `${price}%`
                                        }}
                                    ></div>
                                    <Slider
                                        value={[price]}
                                        min={1}
                                        max={99}
                                        step={1}
                                        onValueChange={([value]) => setPrice(value)}
                                        className="relative z-10"
                                    />
                                </div>
                                <div className="flex justify-between text-xs text-gray-400 mt-1">
                                    <span>1%</span>
                                    <span>99%</span>
                                </div>
                            </div>
                        ) : (
                            <div className="mb-6">
                                <GoldHeader>Execution Price</GoldHeader>
                                <div className="bg-black bg-opacity-50 p-3 rounded-lg">
                                    <div className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r" style={{ backgroundImage: `linear-gradient(to right, ${DARK_GOLD}, ${LIGHT_GOLD})` }}>${executionPrice.toFixed(4)}</div>
                                    <div className="text-sm text-gray-400 text-center">Current market price</div>
                                </div>
                            </div>
                        )}
                        <div className='my-5 flex flex-row justify-between items-center'>
                            {onChainMarket &&
                                <div className='text-left text-sm'>
                                    <div>
                                        Fee {onChainMarket.makerFeeRate * 100}% ({((Number(quantity) * Number(price / 100)) * (Number(onChainMarket.makerFeeRate))).toFixed(2)} USDT)
                                    </div>
                                    {/* <div>
                                        Taker fee rate: {onChainMarket.takerFeeRate * 100}% ({((Number(quantity) * Number(price / 100)) * (Number(onChainMarket.takerFeeRate))).toFixed(2)} USDT)
                                    </div> */}
                                </div>
                            }
                        </div>
                        {price && quantity && onChainMarket !== null &&
                            <div className='my-6 flex flex-col md:flex-row justify-around text-center'>
                                <div className='mb-2 md:mb-0'>
                                    <div className='text-sm font-bold'>
                                        Probability
                                    </div>
                                    <span className='text-gold text-xl'>
                                        {((Number(price))).toFixed(2)}%
                                    </span>
                                </div>
                                <div className='mb-2 md:mb-0'>
                                    <div className='text-sm font-bold'>
                                        Shares
                                    </div>
                                    <span className='text-gold text-xl'>
                                        {((Number(quantity)))}
                                    </span>
                                </div>
                                <div className='mb-2 md:mb-0'>
                                    <div className='text-sm font-bold'>
                                        Cost
                                    </div>
                                    <span className='text-gold text-xl'>
                                        {((Number(quantity) * Number(price / 100)) * (1 + Number(onChainMarket.makerFeeRate))).toFixed(2)} {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : 'USDT'}
                                    </span>
                                </div>
                                <div>
                                    <div className='text-sm font-bold'>
                                        Potential
                                    </div>
                                    <span className='text-emerald-500 text-xl '>
                                        ${Number(quantity).toFixed(2)} ({(((Number(quantity) - (Number(quantity) * (Number(price) / 100)) * (1 + Number(onChainMarket.makerFeeRate))) / ((Number(quantity) * (Number(price) / 100)) * (1 + Number(onChainMarket.makerFeeRate)))) * 100).toFixed(2)}%)
                                    </span>
                                </div>
                            </div>
                        }

                        {error && <div className='my-2 text-red-500'>{error}</div>}

                        <div className="flex space-x-4 font-semibold">
                            {side === "YES" &&
                                <button
                                    disabled={!onChainMarket}
                                    onClick={openConfirmModal}
                                    className="w-full py-2 px-4 bg-emerald-500 text-white rounded-md shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    Trade
                                </button>
                            }
                            {side === "NO" &&
                                <button
                                    disabled={!onChainMarket}
                                    onClick={openConfirmModal}
                                    className="w-full py-2 px-4 bg-rose-500 text-white rounded-md shadow-sm hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Trade
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </TooltipPrimitive.Provider>
        </>
    )
}

export default OrderPanel