import { gql } from "@apollo/client";


export const GRAPH_QUERY = gql`
query marketPrices($marketId: String!, $start: timestamptz!) {
  option_markets_binaryoptionmarket(where: {market_id: {_eq: $marketId}}) {
    id
    prices(where: {time: {_gte: $start}}, order_by: {time: asc}) {
      time
      price
    }
  }
}
`

export const LAST_PRICE_QUERY = gql`
query marketPrices($marketId: String!) {
  option_markets_binaryoptionmarket(where: {market_id: {_eq: $marketId}}) {
    id
    prices(order_by: {time: desc}, limit: 1) {
      time
      price
    }
  }
}
`

export const MARKET_QUERY = gql`
query getMarket($marketId: String!) {
  option_markets_binaryoptionmarket(where: {market_id: {_eq: $marketId}}) {
    id
    ticker
    market_info
    settlement_timestamp
    paradyze_market_id
    option_1
    option_0
    on_chain_market_created
    market_id
    market_description
    image_url
    expiration_timestamp
    paradyze_market {
      description
      title
      expiration_timestamp
      id
    }
    prices(order_by: {time: desc}, limit: 1){
        time
        price
        filled_shares
      }
  }
}
`