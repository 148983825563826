import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { NETWORKS } from "../../utils/constants";
import { IndexerGrpcAccountApi } from "@injectivelabs/sdk-ts";
import { hexToBech32 } from "../../utils/functions";


const OrderHistoryTable = (props) => {

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const explorerUrl = import.meta.env.VITE_INJ_EXPLORER_URL

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const [subAccount, setSubAccount] = useState(null)

    const [onChainMarket, setOnChainMarket] = useState(null)

    const [orders, setOrders] = useState([])

    const getSubAccount = useCallback(async (injectiveAddress: string) => {
        const indexerGrpcAccountApi = new IndexerGrpcAccountApi(networkConfig.indexer)
        const subaccountsList = await indexerGrpcAccountApi.fetchSubaccountsList(
            injectiveAddress,
        )
        setSubAccount(subaccountsList[0])
        return subaccountsList
    }, [networkConfig])

    useEffect(() => {
        setOnChainMarket(props.onChainMarket)
        setOrders(props.orders.sort((a, b) => b.createdAt - a.createdAt))
    }, [props.onChainMarket, props.orders, props.trades])

    useEffect(() => {
        if (!connectedAddress) {
            setSubAccount(null)
        }
        else {
            getSubAccount(connectedAddress)
        }
    }, [connectedAddress, getSubAccount])

    return (
        <div className="flex justify-center items-center">
            <div className="w-full">
                {orders.length == 0 &&
                    <div className="text-center mt-5 text-sm">
                        No orders yet...
                    </div>
                }
                <div className="flex flex-row">
                    {orders.length > 0 &&
                        <div className="overflow-x-auto mt-1 text-sm  w-full max-h-80 overflow-y-scroll">
                            <table className="table-auto w-full">
                                <thead className="text-left">
                                    <tr>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Time
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            User
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Side
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end" >
                                            Type
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Price
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Shares
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Margin
                                        </th>
                                        <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                            Filled
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm">
                                    {orders.filter(order => order.state.includes("filled")).map((order, index) => (
                                        <tr
                                            key={index}
                                            className="text-white border-b text-left"
                                        >
                                            <td className="py-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                            </td>
                                            <td className="py-1 px-2">
                                                <a href={`${explorerUrl}account/${hexToBech32(order.subaccountId.slice(0, 42))}`}>
                                                    {subAccount !== null && order.subaccountId == subAccount ?
                                                        <div className="p-1 bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black rounded text-center w-8 ">You</div>
                                                        :
                                                        `${hexToBech32(order.subaccountId.slice(0, 42)).slice(0, 5)}...${hexToBech32(order.subaccountId.slice(0, 42)).slice(-5)}`}
                                                </a>
                                            </td>
                                            <td className="py-1 px-2">
                                                {order.direction == "buy" ?
                                                    <div
                                                        className="text-emerald-500"
                                                    >
                                                        YES
                                                    </div> :
                                                    <div
                                                        className="text-rose-500"
                                                    >
                                                        NO
                                                    </div>
                                                }
                                            </td>
                                            <td className="py-1 px-2 capitalize">
                                                {order.executionType}
                                            </td>
                                            <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {(
                                                    order.direction === "sell"
                                                        ? 1 - Number(order.price) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                        : Number(order.price) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                ).toFixed(4)}{" "}
                                                {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                            </td>
                                            <td className="py-1 px-2">
                                                {order.quantity}
                                            </td>
                                            <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {Number(order.margin) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)}  {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                            </td>
                                            <td className="py-1 px-2">
                                                {(order.filledQuantity / order.quantity * 100).toFixed(2)}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OrderHistoryTable;
