import { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from 'react-router-dom';
import OrderBook from "../OrderBookLiquidity";
import { useQuery } from "@apollo/client";
import { GRAPH_QUERY } from "../../utils/queries";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const OpenOrdersPanel = (props) => {
    const { marketId } = useParams();
    const [showChart, setShowChart] = useState(false)

    const [orders, setOrders] = useState([])
    const [graphStart, setGraphStart] = useState(moment().subtract(7, 'days'))

    const [chartData, setChartData] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)

    const { data: graphData } = useQuery(GRAPH_QUERY, {
        pollInterval: 60000,
        fetchPolicy: "network-only",
        variables: {
            marketId: marketId,
            start: graphStart
        }
    })

    useEffect(() => {
        if (!graphData) return

        const rawData = graphData.option_markets_binaryoptionmarket[0].prices
        const labels = rawData.map((dataPoint) => new Date(dataPoint.time).toLocaleTimeString());
        const prices = rawData.map((dataPoint) => dataPoint.price * 100);

        const data = {
            labels: labels,
            datasets: [
                {
                    label: "Average Weighted Probability",
                    data: prices,
                    borderColor: "rgba(251, 242, 176)",
                    backgroundColor: "rgba(251, 242, 176)",
                    tension: 0.1,
                    pointRadius: 0
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        color: '#fbf2b0'
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            return `Probability: ${tooltipItem.formattedValue}%`;
                        },
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    titleColor: '#ffffff',
                    bodyColor: '#ffffff',
                    borderColor: '#ffffff',
                    borderWidth: 1,
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: "Time",
                        color: '#fbf2b0'
                    },
                    ticks: {
                        color: '#fbf2b0'
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: "Probability",
                        color: '#fbf2b0'
                    },
                    ticks: {
                        color: '#fbf2b0',
                        callback: function (value) {
                            return `${value}%`;
                        },
                    },
                },
            },
        };

        setChartData(data)
        setChartOptions(options)
    }, [graphData])

    useEffect(() => {
        setOrders(props.orders.filter(order => order.isActive == true))
        if (props.orders.filter(order => order.isActive == true).length == 0) setShowChart(true)
    }, [props.onChainMarket, props.orders])

    return (
        <div
            className='bg-black bg-opacity-50 backdrop-blur-sm rounded-lg p-5 shadow-lg text-gold'
        >
            <div className="flex flex-row space-x-2 justify-end">
                <div
                    onClick={() => setShowChart(false)}
                    className={`font-semibold underline hover:cursor-pointer ${showChart ? "text-gray-500" : "text-gold"
                        }`}
                >
                    Open Orders
                </div>
                <div
                    onClick={() => setShowChart(true)}
                    className={`font-semibold underline hover:cursor-pointer ${showChart ? "text-gold" : "text-gray-500"
                        }`}
                >
                    Chart
                </div>
            </div>

            {orders.length > 0 && !showChart &&
                <OrderBook orders={orders} />
            }
            {orders.length == 0 && !showChart &&
                <div className="font-regular mt-20 text-lg">
                    No open orders
                </div>
            }
            {showChart && chartData && chartOptions &&
                < div style={{ height: '420px' }}>
                    <Line data={chartData} options={chartOptions} />
                </div>
            }
        </div >
    );
};

export default OpenOrdersPanel;
