import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { FaCalendarAlt, FaCoins } from "react-icons/fa";
import moment from "moment";
import { humanReadableAmount } from "../../utils/functions";
import { IoIosPricetag } from "react-icons/io";
import * as Popover from '@radix-ui/react-popover';


const MarketDetailsTopBar = (props) => {

    const [marketDetails, setMarketDetails] = useState(null)
    const [onChainMarket, setOnChainMarket] = useState(null)

    useEffect(() => {
        setMarketDetails(props.marketDetails)
        setOnChainMarket(props.onChainMarket)
    }, [props.marketDetails, props.onChainMarket])

    const renderSettlement = (market) => {
        if (market.settlementPrice > 0) {
            return (
                <div className="inline">
                    YES
                </div>
            )
        }
        if (market.settlementPrice == 0) {
            return (
                <div className="inline">
                    NO
                </div>
            )
        }
        return (
            <div className="inline">
                Refunds
            </div>
        )
    }

    return (
        <div
            className="text-gold"
        >
            {marketDetails !== null &&
                <div
                    className="flex flex-col md:flex-row justify-between mb-5"
                >
                    <div className="flex flex-col text-left ">
                        {marketDetails.paradyze_market.description !== marketDetails.market_description &&
                            <div>
                                {marketDetails.paradyze_market.description}
                            </div>
                        }

                        <div

                            className="text-3xl font-aileron font-bold"
                        >
                            {marketDetails.market_description}
                        </div>
                    </div>

                    <div
                        className="flex flex-col md:flex-row md:justify-center md:space-x-8 md:items-center"
                    >
                        {marketDetails.prices.length > 0 &&
                            <div
                                className="flex flex-col bg-black bg-opacity-50 p-4 rounded-lg"
                            >
                                <div className="mb-1 text-left md:text-center font-semibold text-lg">
                                    Probability
                                </div>
                                <div
                                    className="flex flex-row items-center"
                                >
                                    <IoIosPricetag className="mr-2" />
                                    <div>{(marketDetails.prices[0].price * 100).toFixed(2)}%</div>

                                </div>
                            </div>
                        }
                        <div
                            className="flex flex-col bg-black bg-opacity-50 p-4 rounded-lg"
                        >
                            <div className="mb-1 text-left md:text-center font-semibold text-lg">
                                Volume
                            </div>
                            <div
                                className="flex flex-row items-center"
                            >
                                <FaCoins className="mr-2" />
                                {marketDetails.prices.length > 0 ?
                                    <div>${humanReadableAmount(marketDetails.prices[0].filled_shares)} USDT</div> : <div>0 USDT</div>
                                }
                            </div>
                        </div>
                        {onChainMarket && moment.unix(onChainMarket.expirationTimestamp).isAfter(moment()) &&
                            <div
                                className="flex flex-col bg-black bg-opacity-50 p-4 rounded-lg"
                            >
                                <div className="mb-1 text-left md:text-center font-semibold text-lg">
                                    Trading ends in
                                </div>
                                <div
                                    className="flex flex-row items-center"
                                >
                                    <FaCalendarAlt className="mr-2" />
                                    <div>
                                        {moment.unix(onChainMarket.expirationTimestamp).fromNow()}
                                    </div>
                                </div>
                            </div>
                        }
                        {onChainMarket && moment.unix(onChainMarket.expirationTimestamp).isBefore(moment()) && moment.unix(onChainMarket.settlementTimestamp).isAfter(moment()) &&
                            <div
                                className="flex flex-col bg-black bg-opacity-50 p-4 rounded-lg"
                            >
                                <div className="mb-1 text-left md:text-center text-lg">
                                    Settles in
                                </div>
                                <div
                                    className="flex flex-row items-center"
                                >
                                    <FaCalendarAlt className="mr-2" />
                                    <div>
                                        {moment.unix(onChainMarket.settlementTimestamp).fromNow()}
                                    </div>
                                </div>
                            </div>
                        }
                        {onChainMarket && moment.unix(onChainMarket.settlementTimestamp).isBefore(moment()) &&
                            <div
                                className="flex flex-col bg-black bg-opacity-50 p-4 rounded-lg"
                            >
                                <div className="mb-1 text-left md:text-center font-semibold text-lg">
                                    Settled {renderSettlement(onChainMarket)}
                                </div>
                                <div
                                    className="flex flex-row items-center"
                                >
                                    <FaCalendarAlt className="mr-2" />
                                    <div>
                                        {moment.unix(onChainMarket.settlementTimestamp).fromNow()}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="self-center mr-2">
                        <Popover.Root>
                            <Popover.Trigger asChild>
                                <button className="">
                                    <FaInfoCircle className="text-3xl mt-5 md:mt-0" />
                                </button>
                            </Popover.Trigger>

                            <Popover.Content
                                side="top"
                                align="center"
                                sideOffset={8}
                                alignOffset={8}
                                className="max-w-xs md:max-w-md px-3 py-2 bg-black text-white text-base rounded-lg shadow-lg z-10"
                            >
                                {marketDetails.market_info ?? "No info for this market"}
                                <Popover.Arrow className="fill-black" width={8} height={8} />
                            </Popover.Content>
                        </Popover.Root>
                    </div>
                </div>
            }
        </div>
    )
}

export default MarketDetailsTopBar