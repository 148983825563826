import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import WalletConnect from "../WalletConnect";
import { useDispatch } from "react-redux";
import { openSwapModal } from "../../redux/reducers/modal";
import logo from "../../assets/logotextnew.webp";
import { FaBars, FaTimes } from "react-icons/fa";

const CHAIN_ID = import.meta.env.VITE_CHAIN_ID;

const NavBar = () => {
    const dispatch = useDispatch();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = useCallback(() => {
        console.log("toggle")
        setIsMobileMenuOpen(prev => !prev);
    }, []);

    return (
        <header className="flex flex-row items-center justify-between p-4 ">
            <div className="hidden md:flex items-center">
                <Link to="/" className="hover:underline ml-5 text-lg text-white font-semibold">
                    <img src={logo} width={150} alt="Paradyze Logo" />
                </Link>
            </div>
            {/* Burger icon for mobile */}
            <div className="flex md:hidden text-white z-20" onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex flex-grow justify-center space-x-10 text-white text-sm md:text-base">
                <div
                    className="ml-10 hover:cursor-pointer hover:underline"
                    onClick={() => dispatch(openSwapModal())}
                >
                    Get USDT
                </div>
                {CHAIN_ID == "injective-888" &&
                    <a
                        href="https://testnet.faucet.injective.network/"
                        className="ml-10 hover:cursor-pointer hover:underline"
                    >
                        Get INJ
                    </a>
                }
                <Link className="hover:underline ml-10" to={"/leader-board?timeFilter=all"}>
                    Leaderboard
                </Link>
                {CHAIN_ID == "injective-1" &&
                    <a
                        href="https://docs.paradyze.io/default-guide/features/prediction-market"
                        className="ml-10 hover:cursor-pointer hover:underline"
                    >
                        Guide
                    </a>
                }
            </div>

            <div className="m-2">
                <WalletConnect />
            </div>

            {/* Mobile Slide-out Menu */}
            {isMobileMenuOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-90 z-10 flex flex-col items-center space-y-4 pt-20 text-white transform transition-transform duration-300 ease-in-out">
                    <Link to="/" className="hover:underline ml-5 text-lg text-white font-semibold">
                        <img src={logo} width={150} alt="Paradyze Logo" />
                    </Link>
                    <Link
                        className="hover:underline"
                        to="/"
                        onClick={toggleMobileMenu}
                    >
                        Home
                    </Link>
                    <div
                        className="hover:cursor-pointer hover:underline"
                        onClick={() => {
                            dispatch(openSwapModal());
                            toggleMobileMenu();
                        }}
                    >
                        Get USDT
                    </div>
                    {CHAIN_ID == "injective-888" &&
                        <a
                            href="https://testnet.faucet.injective.network/"
                            className="hover:cursor-pointer hover:underline"
                            onClick={toggleMobileMenu}
                        >
                            Get INJ
                        </a>
                    }
                    <Link
                        className="hover:underline"
                        to="/leader-board?timeFilter=all"
                        onClick={toggleMobileMenu}
                    >
                        Leaderboard
                    </Link>
                    {CHAIN_ID == "injective-1" &&
                        <a
                            href="https://docs.paradyze.io/default-guide/features/prediction-market"
                            className="hover:cursor-pointer hover:underline"
                        >
                            Guide
                        </a>
                    }
                </div>
            )}
        </header>
    );
};

export default NavBar;
