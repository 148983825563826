import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-black text-gray-300 py-4 w-full border-t font-aileron">
            <div className="container mx-auto text-center">
                <div className="flex justify-center space-x-4">
                    <Link to="/terms-and-conditions" className="hover:text-white">
                        Terms and Conditions
                    </Link>
                    <span>|</span>
                    <Link to="/privacy-policy" className="hover:text-white">
                        Privacy Policy
                    </Link>
                </div>
                <div className="mt-2">
                    <p className="text-sm">
                        &copy; {new Date().getFullYear()} Paradyze. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
