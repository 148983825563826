import NavBar from "../../components/NavBar";
import logo from "../../assets/ParadyzeLogowText.webp";
import Footer from "../../components/Footer";
import Privacy from "../../components/Privacy";
import TermsModal from "@/components/TermsModal";
import TokenAccessModal from "@/components/TokenAccessModal";
import ErrorModal from "@/components/ErrorModal";
import SwapModal from "@/components/SwapModal";

const PrivacyPolicy = () => {
    return (
        <>
            {/* <TermsModal /> */}
            <TokenAccessModal />
            <ErrorModal />
            <SwapModal />

            <div className="bg-bg-image bg-fixed bg-cover  font-aileron font-regular text-white">
                <NavBar />
                <img className="m-auto mt-4 md:mt-6" src={logo} width={150} />
                <div className="p-4 md:p-5">
                    <h1 className="text-center text-2xl font-bold mb-6">Chainflow Ltd Privacy Policy</h1>
                    <Privacy />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
