import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaCoins } from "react-icons/fa";
import { humanReadableAmount } from "../../utils/functions";

const MarketItem = ({ market }) => {
    const navigate = useNavigate();

    const [totalBet, setTotalBet] = useState(null)
    const [hoveredMarketId, setHoveredMarketId] = useState(null)

    useEffect(() => {
        let total = 0
        market.option_markets.map((option) => {
            return option.prices.map((price) => {
                return total += price.filled_shares
            })
        })
        setTotalBet(total)
    }, [market])

    return (
        <div
            className={
                "bg-[#121212] p-2 md:p-5 rounded-lg shadow-md hover:shadow-lg 0 text-gold border border-gold-gradient-start" +
                " hover:scale-[1.02] transition-all duration-300 hover:shadow-lg bg-opacity-50 hover:bg-opacity-100"
            }
        >
            <div
                className="opacity-100"
            >
                <div className="flex flex-row justify-center items-center mx-2 space-x-5">
                    <div
                        className="text-lg md:text-2xl mb-1 font-aileron font-bold text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                        {market.title}
                    </div>
                    {market.image_url &&
                        <div>
                            <img src={market.image_url} className="rounded-full w-[50px]" />
                        </div>
                    }
                </div>


                <div
                    className="h-40 overflow-y-auto mt-5"
                >
                    {market.option_markets.map((option, index) => {
                        return (
                            <div
                                onMouseEnter={() => setHoveredMarketId(option.market_id)}
                                onMouseLeave={() => setHoveredMarketId(null)}
                                onClick={() => {
                                    if (option.market_id) {
                                        navigate(`/market/${option.market_id}?side=YES`);
                                    }
                                }}
                                key={index}
                                className={hoveredMarketId == option.market_id ?
                                    "relative flex flex-row justify-between bg-[#fcf2b0]/80 text-black p-2 rounded-md shadow-md items-center my-2 border-[#585858] border hover:cursor-pointer h-10" :
                                    "relative flex flex-row justify-between bg-[#070707] p-2 rounded-md shadow-md items-center my-2 border-[#585858] border hover:cursor-pointer h-10 transition-colors"}
                            >
                                {hoveredMarketId == option.market_id &&
                                    <>
                                        <div
                                            className="flex flex-row items-center whitespace-nowrap overflow-hidden text-ellipsis font-aileron font-semibold text-base"
                                        >
                                            {option.market_description}
                                        </div>
                                        <div
                                            className="flex flex-row items-center whitespace-nowrap overflow-hidden text-ellipsis font-aileron font-semibold text-base"

                                        >
                                            {option.onChainMarket && option.onChainMarket.settlementPrice !== "" ? "View Market" : "Trade Now"}
                                        </div>
                                    </>
                                }
                                {hoveredMarketId !== option.market_id &&
                                    <>
                                        <div
                                            className="flex flex-row items-center whitespace-nowrap overflow-hidden text-ellipsis font-aileron font-semibold text-base"
                                        >
                                            {option.market_description}
                                        </div>
                                        {option.prices.length > 0 && option.prices[0].price !== 0 && moment(option.onChainMarket.settlementTimestamp).isAfter(moment()) &&
                                            <div className="flex flex-row justify-between">
                                                <div>
                                                    {(option.prices[0].price * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        }
                                        {option.onChainMarket && option.onChainMarket.settlementPrice == "1000000" &&
                                            <div className="flex flex-row justify-between ml-2 z-[1]">
                                                <div
                                                    onClick={() => {
                                                        if (option.market_id) {
                                                            navigate(`/market/${option.market_id}?side=YES`);
                                                        }
                                                    }}
                                                    className="text-sm bg-emerald-500 hover:bg-emerald-600 rounded text-white font-semibold p-1  hover:cursor-pointer  whitespace-nowrap overflow-hidden text-ellipsis"
                                                >
                                                    Settled Yes
                                                </div>
                                            </div>
                                        }
                                        {option.onChainMarket && option.onChainMarket.settlementPrice == "0" &&
                                            <div className="flex flex-row justify-between ml-2 z-[1]">
                                                <div
                                                    onClick={() => {
                                                        if (option.market_id) {
                                                            navigate(`/market/${option.market_id}?side=NO`);
                                                        }
                                                    }}
                                                    className="text-sm bg-rose-500 hover:bg-rose-600 rounded text-white font-semibold p-1 hover:cursor-pointer  whitespace-nowrap overflow-hidden text-ellipsis"
                                                >
                                                    Settled No
                                                </div>
                                            </div>
                                        }
                                        {option.onChainMarket && option.onChainMarket.settlementPrice == "-1" &&
                                            <div className="flex flex-row justify-between ml-2 z-[1]">
                                                <div
                                                    className="text-sm bg-gold rounded text-black font-semibold p-1"
                                                >
                                                    Refunded
                                                </div>
                                            </div>
                                        }
                                        {option.onChainMarket && moment.unix(option.onChainMarket.settlementTimestamp).isAfter(moment()) && option.prices.length > 0 &&
                                            <div className="flex flex-row justify-between text-white font-semibold z-[1]">
                                                <div className="flex flex-row text-white items-center space-x-2">
                                                    <div className="w-20 h-2 bg-gray-700 rounded-full overflow-hidden">
                                                        <div
                                                            className="h-full bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end rounded-full"
                                                            style={{ width: `${(option.prices[0].price * 100).toFixed(0)}%` }}
                                                        >
                                                        </div>
                                                    </div>
                                                    <div className="text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                        {(option.prices[0].price * 100).toFixed(0)}%
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        )
                    })}
                </div>
                <div
                    className="flex flex-row justify-between mt-5 text-text-gray space-x-2"
                >
                    {totalBet !== null &&
                        <div
                            className="flex flex-row items-center bg-black rounded-lg p-1 text-sm "
                        >
                            <FaCoins className="mr-2" /> Volume: ${humanReadableAmount(totalBet)} USDT
                        </div>
                    }
                    {moment(market.expiration_timestamp).isAfter(moment()) &&
                        <div
                            className="flex flex-row items-center bg-black rounded-lg p-1 text-sm "
                        >
                            <p className="text-sm mr-2">
                                Ends {moment(market.expiration_timestamp).fromNow()}
                            </p>

                            <FaCalendarAlt />
                        </div>
                    }
                    {moment(market.expiration_timestamp).isBefore(moment()) && moment(market.settlement_timestamp).isAfter(moment()) &&
                        <div
                            className="flex flex-row items-center bg-black rounded-lg p-1"
                        >
                            <p className="text-sm mr-2">
                                Settles {moment(market.settlement_timestamp).fromNow()}
                            </p>

                            <FaCalendarAlt />
                        </div>
                    }
                    {moment(market.settlement_timestamp).isBefore(moment()) &&
                        <div
                            className="flex flex-row items-center bg-black rounded-lg p-1"
                        >
                            <p className="text-sm mr-2">
                                Settled {moment(market.settlement_timestamp).fromNow()}
                            </p>

                            <FaCalendarAlt />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MarketItem;
