import { useEffect, useState } from "react";
import logo from "../../assets/ParadyzeLogowText.webp";
import moment from "moment";

export default function MainnetCountdownModal() {
    const isOpen = import.meta.env.VITE_MAINNET_COUNTDOWN_MODAL === 'true';

    const mainnetLaunchDate = new Date('2024-11-10T20:00:00Z');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const difference = mainnetLaunchDate - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    }

    return (
        <div className="font-aileron font-regular text-white">
            {isOpen && moment(mainnetLaunchDate) > moment() &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg p-6 rounded-lg shadow-lg mx-5 text-center">
                        <img className="mx-auto" src={logo} width={150} alt="logo" />
                        <div className="mt-4">
                            <h2 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">Mainnet Launch Countdown</h2>
                            {timeLeft.days !== undefined ? (
                                <div className="text-2xl font-bold mt-2">
                                    {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                                </div>
                            ) : (
                                <div className="text-2xl font-bold mt-2">Mainnet is live!</div>
                            )}
                        </div>
                        <div className="mt-4">
                            Testnet has moved to <a href="https://testnet.paradyze.io/" className="underline">testnet.paradyze.io</a>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}