import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { useSearchParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import logo from "../../assets/ParadyzeLogowText.webp";
import paranite from "../../assets/Paranite2_Small.png";
import Footer from "../../components/Footer";
import SwapModal from "../../components/SwapModal";
import { useSelector } from "react-redux";
import { humanReadableAmount } from "@/utils/functions";

const LEADER_BOARD_QUERY = gql`query WalletsByHighestPnL($startTime: timestamptz = "1970-01-01T00:00:00Z") {
    paradyze_core_wallet(
      order_by: { 
        positions_aggregate: { 
          sum: { profit_loss: desc_nulls_last } 
        }
      }
    ) {
      address
      total_profit_loss: positions_aggregate(
        where: { 
          time: { _gte: $startTime }
        }
      ) {
        aggregate {
          sum {
            profit_loss
          }
          sum {
            total_margin
          }
        }
      }
    }
  }
`;


const LeaderBoard = () => {
    const connectedAddress = useSelector(state => state.wallet.connectedAddress);

    const [searchParams, setSearchParams] = useSearchParams();
    const explorerUrl = import.meta.env.VITE_INJ_EXPLORER_URL

    const initialTimeFilter = searchParams.get("timeFilter") || "all";
    const [timeFilter, setTimeFilter] = useState(initialTimeFilter);
    const [timeVar, setTimeVar] = useState();

    const { data, error, loading } = useQuery(LEADER_BOARD_QUERY, {
        variables: timeFilter === "all" ? {} : { startTime: timeVar },
    });

    const [walletSearch, setWalletSearch] = useState("")

    useEffect(() => {
        const searchParamTimeFilter = searchParams.get("timeFilter");
        if (searchParamTimeFilter && searchParamTimeFilter !== timeFilter) {
            setTimeFilter(searchParamTimeFilter);
        }
    }, [searchParams, timeFilter]);

    useEffect(() => {
        if (timeFilter == "week") {
            setTimeVar(moment().subtract(1, 'week'))
        }
        if (timeFilter == "month") {
            setTimeVar(moment().subtract(1, 'month'))
        }
        if (timeFilter == "all") {
            setTimeVar(null)
        }
    }, [timeFilter])

    return (
        <>
            <SwapModal />

            <div className="flex flex-col bg-bg-image bg-fixed bg-cover h-screen overflow-y-scroll font-aileron font-regular text-white">
                <NavBar />
                <div className="flex-grow">
                    <img className="m-auto mt-4 md:mt-6" src={logo} width={150} />
                    <div
                        className="inline text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end font-bold text-2xl"
                    >
                        Leaderboard
                    </div>
                    <div className="text-center mt-6 space-x-4 space-y-2">
                        <button
                            className={`p-1 rounded w-32 font-aileron font-semibold ${searchParams.get('timeFilter') === 'week'
                                ? 'bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black'
                                : 'bg-gray-300 text-gray-600'
                                }`}
                            onClick={() => setSearchParams({ timeFilter: 'week' })}
                        >
                            Past Week
                        </button>
                        <button
                            className={`p-1 rounded w-32 font-aileron font-semibold ${searchParams.get('timeFilter') === 'month'
                                ? 'bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black'
                                : 'bg-gray-300 text-gray-600'
                                }`}
                            onClick={() => setSearchParams({ timeFilter: 'month' })}
                        >
                            Past Month
                        </button>
                        <button
                            className={`p-1 rounded w-32 font-aileron font-semibold ${searchParams.get('timeFilter') === 'all'
                                ? 'bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black'
                                : 'bg-gray-300 text-gray-600'
                                }`}
                            onClick={() => setSearchParams({ timeFilter: 'all' })}
                        >
                            All Time
                        </button>
                    </div>

                    <div className="mt-5 items-center text-white mx-2">
                        Wallet search: <input
                            value={walletSearch}
                            onChange={(e) => setWalletSearch(e.target.value)}
                            className="ml-0 md:ml-5 border rounded w-full md:w-1/3 px-2"
                        />
                    </div>
                    <div className="flex flex-row justify-center space-x-5">
                        <div
                            onClick={() => setWalletSearch(connectedAddress)}
                            className="mt-2 text-sm underline hover:cursor-pointer"
                        >
                            Set to connected wallet
                        </div>
                        <div
                            onClick={() => setWalletSearch("")}
                            className="mt-2 text-sm underline hover:cursor-pointer"
                        >
                            Clear
                        </div>
                    </div>



                    <div className="mt-8 pb-10 overflow-x-auto mx-2">
                        {loading ? (
                            <p className="text-center">Loading...</p>
                        ) : (
                            <table className="m-auto w-3/4 text-center">
                                <thead>
                                    <tr className="">
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">Position</th>
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">Wallet Address</th>
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">Total Volume</th>
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">Total P&L</th>
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">P&L %</th>
                                        <th className="border-b-2 p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end items-center">
                                            Paranite <img src={paranite} className="h-[18px] inline ml-2" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.paradyze_core_wallet
                                        .filter(x => x.total_profit_loss.aggregate.sum.total_margin != null && x.total_profit_loss.aggregate.sum.total_margin != 0)
                                        .filter(x => !walletSearch || x.address.toLowerCase().includes(walletSearch.toLowerCase()))
                                        .sort((a, b) => b.total_profit_loss.aggregate.sum.profit_loss - a.total_profit_loss.aggregate.sum.profit_loss)
                                        .map((wallet, index) => {

                                            const profitLoss = wallet.total_profit_loss.aggregate.sum.profit_loss;
                                            const totalMargin = wallet.total_profit_loss.aggregate.sum.total_margin;
                                            const pnlPercentage = ((profitLoss / totalMargin) * 100).toFixed(2);

                                            return (
                                                <tr key={wallet.address}>
                                                    <td className="border-b p-2">{index + 1}</td>
                                                    <td className={connectedAddress == wallet.address ? "border-b p-2 text-gold font-semibold" : "border-b p-2"}>
                                                        <a href={`${explorerUrl}account/${wallet.address}`}>
                                                            {wallet.address}
                                                        </a>
                                                    </td>
                                                    <td className="border-b p-2">
                                                        ${humanReadableAmount(totalMargin)}
                                                    </td>
                                                    <td
                                                        className={
                                                            profitLoss > 0
                                                                ? `text-emerald-500 border-b p-2`
                                                                : `text-rose-500 border-b p-2`
                                                        }
                                                    >
                                                        ${humanReadableAmount(profitLoss)}
                                                    </td>
                                                    <td className={
                                                        pnlPercentage > 0
                                                            ? `text-emerald-500 border-b p-2`
                                                            : `text-rose-500 border-b p-2`
                                                    }>
                                                        {pnlPercentage}%
                                                    </td>
                                                    <td className="border-b p-2">
                                                        {humanReadableAmount(totalMargin)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default LeaderBoard;
