import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { LAST_PRICE_QUERY } from "../../utils/queries";
import { useEffect, useState } from "react";
import { humanReadableAmount } from "@/utils/functions";

const OrderBook = ({ orders }) => {

    const { marketId } = useParams();
    const [lastPrice, setLastPrice] = useState(null)

    const { data: data, loading: loading } = useQuery(LAST_PRICE_QUERY, {
        pollInterval: 60000,
        fetchPolicy: "network-only",
        variables: {
            marketId: marketId,
        }
    })

    useEffect(() => {
        if (
            !data ||
            !data.option_markets_binaryoptionmarket ||
            data.option_markets_binaryoptionmarket.length === 0 ||
            !data.option_markets_binaryoptionmarket[0].prices ||
            data.option_markets_binaryoptionmarket[0].prices.length === 0
        ) return;

        setLastPrice(data.option_markets_binaryoptionmarket[0].prices[0].price);
    }, [data]);

    const buyOrders = orders
        .filter(order => order.direction === 'buy')
        .sort((a, b) => b.price - a.price);

    const sellOrders = orders
        .filter(order => order.direction === 'sell')
        .sort((a, b) => a.price - b.price);


    const calculateCumulativeMargin = (orders) => {
        const levels = {};
        orders.forEach(order => {
            const roundedPrice = parseFloat(order.price / Math.pow(10, 6)).toFixed(2)
            if (levels[roundedPrice]) {
                levels[roundedPrice] += parseFloat(order.margin);
            } else {
                levels[roundedPrice] = parseFloat(order.margin);
            }
        });

        return Object.entries(levels).map(([price, margin]) => ({
            price: parseFloat(price),
            margin,
        }));
    };

    const buyLevels = calculateCumulativeMargin(buyOrders);
    const sellLevels = calculateCumulativeMargin(sellOrders);

    const bestBuy = buyOrders.length > 0 ? buyOrders[0].price : null;
    const bestSell = sellOrders.length > 0 ? sellOrders[0].price : null;
    const spread = bestBuy && bestSell ? bestSell - bestBuy : null;

    return (
        <div className="p-2 rounded-lg shadow-md max-h-[200px] md:max-h-[500px] overflow-y-scroll">
            <div
                className="flex flex-row"
            >
                {lastPrice &&
                    <div className="mr-10">
                        <div>Chance</div>
                        <div
                            className={`${((lastPrice) * 100) > 50 ? "text-emerald-500" : "text-rose-500"} text-2xl`}
                        >
                            {((lastPrice) * 100).toFixed(2)}%
                        </div>
                    </div>
                }
            </div>
            {spread && (
                <div className="">
                    <h3 className="text-md font-semibold">Spread: ${spread / Math.pow(10, 6)}</h3>
                </div>
            )}
            <div className="flex justify-between">
                <div className="text-emerald-500 font-semibold">Buy Orders</div>
                <div className="text-rose-500 font-semibold">Sell Orders</div>
            </div>
            <div className="flex justify-between">
                <div className="flex flex-col w-1/2 mr-1">
                    {buyLevels.sort((a, b) => b.price - a.price).map(level => (
                        <div key={level.price} className="flex justify-between py-1 relative items-center">
                            <span>{(level.price).toFixed(2)}</span>
                            <span className="text-emerald-500">${humanReadableAmount(level.margin / Math.pow(10, 6))}</span>
                            <div
                                className="absolute right-0 bg-emerald-500 bg-opacity-10 h-full rounded-sm"
                                style={{ width: `${(level.margin / Math.max(...buyLevels.map(o => o.margin))) * 100}%` }}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-1/2 text-right ml-1">
                    {sellLevels.sort((a, b) => a.price - b.price).map(level => (
                        <div key={level.price} className="flex justify-between py-1 relative items-center">
                            <span className="text-rose-500">${humanReadableAmount(level.margin / Math.pow(10, 6))}</span>
                            <span>{(1 - (level.price)).toFixed(2)}</span>
                            <div
                                className="absolute left-0 bg-rose-500 bg-opacity-10 h-full rounded-sm"
                                style={{ width: `${(level.margin / Math.max(...sellLevels.map(o => o.margin))) * 100}%` }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrderBook;
