import { useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { clearConnectedAddress, clearConnectedWallet } from '../../redux/reducers/wallet';
import { openWalletConnectModal } from "../../redux/reducers/modal";
import SelectWalletModal from "../SelectWalletModal";
import { getWallet } from "../../utils/transaction";

const WalletConnect = (props: { hideNetwork?: boolean, button?: boolean }) => {

    const dispatch = useDispatch();
    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const connectedWallet = useSelector(state => state.wallet.connectedWallet);

    const disconnect = useCallback(async () => {
        console.log("disconnect");
        const chainId = import.meta.env.VITE_CHAIN_ID
        const wallet = getWallet();
        if (!connectedWallet) {
            try {
                await window.keplr.disable();
                dispatch(clearConnectedAddress());
            }
            catch (e) {
                await window.leap.disconnect(chainId)
                dispatch(clearConnectedAddress());
            }
        }
        else if (connectedWallet == "leap") {
            await wallet.disconnect(chainId)
            dispatch(clearConnectedAddress());
        }
        else if (connectedWallet == "keplr") {
            try {
                await wallet.disable();
                dispatch(clearConnectedAddress());
            }
            catch (e) {
                await window.leap.disconnect(chainId)
                dispatch(clearConnectedAddress());
            }
        }
        dispatch(clearConnectedWallet());
    }, [connectedWallet, dispatch])

    return (
        <>
            <SelectWalletModal />
            <div
                className="bg-black opacity-90 p-1 rounded-lg w-32 font-aileron font-bold"
            >
                {connectedAddress ? (
                    <div onClick={disconnect} className="text-sm hover:cursor-pointer text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                        <div className='mx-2'>
                            <div>{connectedAddress.slice(0, 5)}...{connectedAddress.slice(-5)}</div>
                            <div className="" >Disconnect</div>
                        </div>
                    </div>
                ) : (
                    <div className="text-sm">
                        <button
                            className="p-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end"
                            onClick={() => dispatch(openWalletConnectModal())}
                        >
                            Connect Wallet
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default WalletConnect