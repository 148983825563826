import { bech32 } from "bech32"
import { Buffer } from "buffer";

export function calculateNetFilledQuantityAndAveragePrice(orders) {
    const filledQuantities = {};
    let totalWeightedPrice = 0;
    let totalFilledQuantity = 0;

    orders.forEach(order => {
        const price = parseFloat(order.executionPrice) / Math.pow(10, 6);
        const subaccountId = order.subaccountId;
        const filledQuantity = parseInt(order.executionQuantity);
        const direction = order.tradeDirection;

        const type = order.tradeExecutionType
        if (type == "expiryMarketSettlement") return

        const key = `${price}-${subaccountId}`;

        if (!filledQuantities[key]) {
            filledQuantities[key] = 0;
        }

        if (direction === 'buy') {
            filledQuantities[key] += filledQuantity;
        }
    });

    for (const key in filledQuantities) {
        const quantity = filledQuantities[key];
        if (quantity > 0) {
            const price = parseFloat(key.split('-')[0]);
            totalWeightedPrice += price * quantity;
            totalFilledQuantity += quantity;
        }
    }

    const weightedAveragePrice = totalFilledQuantity > 0 ? totalWeightedPrice / totalFilledQuantity : 0;

    return {
        totalFilledQuantity,
        weightedAveragePrice
    };
}


function convertBits(data, fromBits, toBits) {
    let accumulator = 0;
    let bits = 0;
    const maxv = (1 << toBits) - 1;
    const result = [];

    for (let i = 0; i < data.length; i++) {
        accumulator = (accumulator << fromBits) | data[i];
        bits += fromBits;

        while (bits >= toBits) {
            bits -= toBits;
            result.push((accumulator >> bits) & maxv);
        }
    }

    if (bits > 0) {
        result.push((accumulator << (toBits - bits)) & maxv);
    }

    return result;
}

export function hexToBech32(hexAddress, prefix = "inj") {
    if (hexAddress.startsWith("0x")) {
        hexAddress = hexAddress.slice(2);
    }

    const addressBytes = Buffer.from(hexAddress, 'hex');
    const fiveBitAddress = convertBits(addressBytes, 8, 5);
    const bech32Address = bech32.encode(prefix, fiveBitAddress);

    return bech32Address;
}

export function humanReadableAmount(number) {
    if (!number) {
        return 0
    }
    const units = ["", "k", "m", "b", "t"];
    let unitIndex = 0;

    while (number >= 1000 && unitIndex < units.length - 1) {
        number /= 1000;
        unitIndex++;
    }

    return `${number.toFixed(number >= 10 ? 0 : 2)}${units[unitIndex]}`;
}