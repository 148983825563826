import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { closeSwapModal } from "../../redux/reducers/modal";
import SpotMarketAPI from "../../modules/SpotMarketAPI";
import { NETWORKS } from "../../utils/constants";
import { getOfflineSigner, handleSendTx } from "../../utils/transaction";
import { Buffer } from "buffer";


const TESTNET_INJ_USDT_MARKET = import.meta.env.VITE_INJ_USDT_MARKET
const TESTNET_USDT = import.meta.env.VITE_USDT_DENOM

const SELL = 2

const chainId = import.meta.env.VITE_CHAIN_ID

export default function SwapModal() {
    const networkConfig = useMemo(() => {
        return NETWORKS[chainId];
    }, []);

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);

    const isOpen = useSelector((state) => state.modals.isSwapModalOpen);
    const dispatch = useDispatch();

    const [injBalance, setInjBalance] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(null);

    const [amountINJ, setAmountINJ] = useState(0);
    const [receivedUSDT, setReceivedUSDT] = useState(0);
    const [price, setPrice] = useState(0);
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const closeModal = () => {
        dispatch(closeSwapModal());
    };

    const getBalance = useCallback(async () => {
        if (!connectedAddress) return
        const module = new SpotMarketAPI(networkConfig)
        const injBal = await module.getBalanceOfToken('inj', connectedAddress)
        setInjBalance(injBal['amount'] / Math.pow(10, 18))
        const usdtBal = await module.getBalanceOfToken(TESTNET_USDT, connectedAddress)
        setUsdtBalance(usdtBal['amount'] / Math.pow(10, 6))
    }, [connectedAddress, networkConfig])

    useEffect(() => {
        getBalance()
    }, [connectedAddress, getBalance])

    const handleAmountChange = useCallback(async (e) => {
        const injValue = e.target.value;
        setAmountINJ(injValue);
        const module = new SpotMarketAPI(networkConfig)
        const amountBack = await module.getHelixMarketQuote(TESTNET_INJ_USDT_MARKET, injValue, 18)
        const sellQuote = amountBack['sellQuoteAmount']

        setPrice(amountBack['averageSellPrice'] * Math.pow(10, 12))
        setReceivedUSDT(sellQuote * Math.pow(10, 12))

    }, [networkConfig]);

    const handleSwap = useCallback(async () => {
        setError(null)
        setSuccess(false)
        console.log(`Swapping ${amountINJ} INJ for ${receivedUSDT} USDT at ${price}`);
        const module = new SpotMarketAPI(networkConfig)
        const msg = await module.constructSpotMarketOrder(TESTNET_INJ_USDT_MARKET, price, amountINJ, SELL, 18, 6, connectedAddress)

        const { key, offlineSigner } = await getOfflineSigner(networkConfig.chainId);
        const pubKey = Buffer.from(key.pubKey).toString("base64");
        const injectiveAddress = key.bech32Address;

        if (connectedAddress !== injectiveAddress) {
            setError("Wrong wallet connected")
            return
        }
        else {
            setError(null)
        }

        handleSendTx(networkConfig, pubKey, msg, injectiveAddress, offlineSigner).then(r => {
            getBalance()
            setAmountINJ(0)
            setSuccess(true)
        }).catch(e => {
            console.log(e)
            setError(e.message)
        })

    }, [amountINJ, receivedUSDT, price, networkConfig, connectedAddress, getBalance]);

    return (
        <div className="font-aileron font-regular text-white">
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-gradient-to-b from-black to-neutral-700 max-w-screen-lg p-6 rounded-lg shadow-lg mx-5">
                        <h2 className="font-bold text-2xl mb-4 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                            Swap INJ to USDT
                        </h2>
                        <div className="my-5">
                            <p className="text-sm">Powered by the Injective orderbook</p>
                            <a
                                href={chainId == "injective-888" ? "https://testnet.helixapp.com/spot/inj-usdt" : "https://helixapp.com/spot/inj-usdt"}
                                className="underline text-sm"
                            >
                                Trade on Helix instead
                            </a>
                            <label className="block text-white my-4">Amount of INJ to Swap:</label>
                            <input
                                type="number"
                                value={amountINJ}
                                onChange={handleAmountChange}
                                className="w-full px-3 py-2 mb-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gold-gradient-end text-black bg-white"
                                placeholder="Enter amount of INJ"
                            />
                            {injBalance &&
                                <p className="text-white text-sm">Your INJ balance <strong>{injBalance.toFixed(4)} INJ</strong></p>
                            }
                            {usdtBalance &&
                                <p className="text-white text-sm">Your USDT balance <strong>{usdtBalance.toFixed(4)} USDT</strong></p>
                            }
                            <p className="text-white mt-5">You will receive approximately: <strong>{receivedUSDT.toFixed(4)} USDT</strong></p>
                            {error && <div className='my-2 text-red-500'>{error}</div>}
                            {success && <div className='my-2 text-emerald-500'>Swap complete</div>}
                        </div>
                        <div className="flex space-x-4">
                            <button
                                onClick={handleSwap}
                                disabled={!amountINJ}  // Disable the button if no amount is entered
                                className={`w-full px-4 py-2 rounded-lg font-semibold ${amountINJ ? 'bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end text-black' : 'bg-gray-500 text-white cursor-not-allowed'}`}
                            >
                                Confirm Swap
                            </button>
                            <button
                                onClick={closeModal}
                                className="w-full px-4 py-2 rounded-lg font-semibold bg-gray-600 text-white"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
