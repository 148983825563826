import MarketDetailsTopBar from "@/components/MarketDetailsTopBar"
import MarketOrderHistory from "@/components/MarketOrderHistory"
import OpenOrdersPanel from "@/components/OpenOrdersPanel"
import OrderPanel from "@/components/OrderPanel"
import SwapModal from "@/components/SwapModal"
import NavBar from "@/components/NavBar"
import BinaryOptionMarketAPI from "@/modules/BinaryOptionMarketAPI"
import { NETWORKS } from "@/utils/constants"
import { MARKET_QUERY } from "@/utils/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FaArrowLeftLong } from "react-icons/fa6"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { openTermsModal } from "@/redux/reducers/modal"
import { MoonLoader } from "react-spinners"
import MainnetCountdownModal from "@/components/MainnetCountdownModal"


const MarketDetails = () => {
    const dispatch = useDispatch();

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const { marketId } = useParams();

    const [marketDetails, setMarketDetails] = useState(null)
    const [onChainMarket, setOnChainMarket] = useState(null);

    const [orders, setOrders] = useState([])
    const [trades, setTrades] = useState([])

    const [streaming, setStreaming] = useState(false)
    const [ordersLoaded, setOrdersLoaded] = useState(false);

    const [loading, setLoading] = useState(true);
    const [onChainFetched, setOnChainFetched] = useState(false);


    const { data } = useQuery(MARKET_QUERY, {
        skip: marketId == null,
        fetchPolicy: 'network-only',
        variables: {
            marketId: marketId
        }
    })

    useEffect(() => {
        const termsAccepted = localStorage.getItem('termsAccepted');
        if (!termsAccepted) {
            dispatch(openTermsModal());
        }
    }, [dispatch])

    useEffect(() => {
        if (!data) return
        setMarketDetails(data.option_markets_binaryoptionmarket[0])
    }, [data])

    const getOptionMarket = useCallback(async (marketId) => {
        const module = new BinaryOptionMarketAPI(networkConfig);
        try {
            const market = await module.fetchBinaryOptionMarket(marketId);
            const orders = await module.getDerivativeMarketOrders(marketId)
            const trades = await module.getDerivativeMarketTrades(marketId)

            setOrders(orders.sort((a, b) => b.createdAt - a.createdAt))
            setTrades(trades.sort((a, b) => b.executedAt - a.executedAt))

            console.log("updated orders and market")
            return market
        } catch (error) {
            console.error('Failed to fetch option markets:', error);
            throw error;
        }
    }, [networkConfig]);

    useEffect(() => {
        if (!ordersLoaded || (!onChainMarket && marketId)) {
            setLoading(true)
            getOptionMarket(marketId).then(r => {
                setOnChainMarket(r)
                setLoading(false)
                setOrdersLoaded(true)
                setOnChainFetched(true)
            }).catch(e => {
                console.log(e)
            })
        }
    }, [getOptionMarket, onChainMarket, marketId, ordersLoaded])

    const orderBookCallback = useCallback(() => {
        setOrdersLoaded(false)
    }, [])

    useEffect(() => {
        setOrdersLoaded(false);
        if (marketId && networkConfig && !streaming) {
            setStreaming(true)
            const module = new BinaryOptionMarketAPI(networkConfig);
            const streamFn = module.streamOrderBook(marketId, orderBookCallback);
            return () => {
                if (streamFn && typeof streamFn === 'function') {
                    setStreaming(false)
                }
            };
        }
    }, [marketId, networkConfig, orderBookCallback, streaming]);

    return (
        <>
            <MainnetCountdownModal />
            <SwapModal />
            <div className="flex flex-col bg-bg-image bg-fixed bg-cover min-h-screen  font-aileron font-regular">
                <NavBar />

                <div className="flex-grow">
                    <div className="mx-2 md:mx-5 mb-10">
                        <Link
                            to={"/"}
                            className={"font-aileron font-semibold flex flex-row items-center mb-5 text-center bg-gradient-to-r from-gold-gradient-start to-gold-gradient-end w-24 text-black p-2 rounded-full shadow-lg text-sm hover:cursor-pointer"}
                        >
                            <FaArrowLeftLong className="mr-2" />
                            Markets
                        </Link>
                        <div>
                            <MarketDetailsTopBar
                                marketDetails={marketDetails}
                                onChainMarket={onChainMarket}
                            />
                        </div>
                        {loading && !onChainFetched &&
                            <div className="flex">
                                <MoonLoader loading={loading} color="gold" size={80} className="m-auto mt-20" />
                            </div>
                        }
                        {onChainFetched &&
                            <>
                                <div className={`grid gap-4 justify-center mb-4 ${onChainMarket && moment.unix(onChainMarket.expirationTimestamp).isAfter(moment()) ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'}`}>
                                    <OpenOrdersPanel
                                        onChainMarket={onChainMarket}
                                        orders={orders}
                                    />
                                    {onChainMarket && moment.unix(onChainMarket.expirationTimestamp).isAfter(moment()) && (
                                        <OrderPanel
                                            marketDetails={marketDetails}
                                            onChainMarket={onChainMarket}
                                            orders={orders}
                                        />
                                    )}
                                </div>
                                <MarketOrderHistory
                                    onChainMarket={onChainMarket}
                                    orders={orders}
                                    trades={trades}
                                    setOrdersLoaded={setOrdersLoaded}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default MarketDetails