

const Privacy = () => {

    return (
        <div className="text-justify leading-relaxed mx-2">

            <h3 className="text-center mb-4">Effective Date: September 2024</h3>

            <p className="mt-4">
                Chainflow Ltd (“Company,” “we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect,
                use, disclose, and safeguard your personal information when you visit our website or use our services (collectively, the “Services”).
                By accessing or using the Services, you consent to the collection and use of your personal information as described in this policy.
                If you do not agree with the terms of this Privacy Policy, please do not access the Site or use the Services.
            </p>

            <h3 className="font-bold mt-6">1. Information We Collect</h3>
            <p className="mt-2">
                We collect several types of information from and about users of our Services, including:
            </p>

            <h4 className="font-semibold mt-4">1.1. Personal Information You Provide</h4>
            <p className="mt-2">
                When you contact us or interact with our Services, you may provide us with personal information such as:
            </p>

            <ul className="list-disc ml-6">
                <li>Name</li>
                <li>Email Address</li>
                <li>Geolocation Data</li>
                <li>Records and Copies of Your Correspondence (including email addresses)</li>
            </ul>

            <h4 className="font-semibold mt-4">1.2. Automatically Collected Information</h4>
            <p className="mt-2">
                As you navigate through the Site and use the Services, we may collect certain information automatically, including:
            </p>

            <ul className="list-disc ml-6">
                <li>IP Address</li>
                <li>Browser Type and Version</li>
                <li>Operating System</li>
                <li>Referring URLs</li>
                <li>Access Times</li>
                <li>Pages Viewed</li>
                <li>Clickstream Data</li>
                <li>Usage Details</li>
                <li>Information Collected Through Cookies and Other Tracking Technologies</li>
            </ul>

            <h4 className="font-semibold mt-4">1.3. Geolocation Information</h4>
            <p className="mt-2">
                We collect information that identifies your location with reasonable specificity using longitude and latitude coordinates obtained
                through GPS, Wi-Fi, cell-site triangulation, or other locational data. This information is used for fraud prevention and risk management
                purposes, among other reasons.
            </p>

            <h4 className="font-semibold mt-4">1.4. Information from Third Parties</h4>
            <p className="mt-2">
                We may receive information from third parties, such as business partners or third-party providers (e.g., Metamask, Keplr Wallet),
                where you have authorized the Site to access this information.
            </p>

            <h3 className="font-bold mt-6">2. How We Use Your Information</h3>
            <p className="mt-2">
                We use the information we collect in various ways, including to:
            </p>

            <ul className="list-disc ml-6">
                <li>Provide, Operate, and Maintain the Services</li>
                <li>Improve, Personalize, and Expand the Services</li>
                <li>Understand and Analyze How You Use the Services</li>
                <li>Develop New Products, Services, Features, and Functionalities</li>
                <li>Communicate with You, including for customer service, updates, and marketing</li>
                <li>Process Transactions and Manage Accounts</li>
                <li>Prevent Fraud, Detect Security Breaches, and Enhance Risk Management</li>
                <li>Monitor and Analyze Trends, Usage, and Activities in connection with the Services</li>
                <li>Comply with Legal Obligations and Protect Against Legal Claims</li>
                <li>Carry Out Any Other Purpose for which the information was collected</li>
            </ul>

            <h3 className="font-bold mt-6">Legal Basis for Processing</h3>
            <p className="mt-2">
                We process your personal data based on the following legal grounds:
            </p>

            <ul className="list-disc ml-6">
                <li><strong>Consent:</strong> For sending marketing communications and newsletters.</li>
                <li><strong>Performance of a Contract:</strong> To provide and maintain our Services.</li>
                <li><strong>Legal Obligation:</strong> To comply with applicable laws, regulations, and legal processes.</li>
                <li><strong>Legitimate Interests:</strong> To improve our Services, conduct analytics, and prevent fraud.</li>
            </ul>

            <h3 className="font-bold mt-6">3. Cookies and Tracking Technologies</h3>
            <p className="mt-2">
                We use cookies, web beacons, pixel tags, and other tracking technologies to collect and store information about your use of the Site.
                These technologies help us recognize you as a user, customize the Services, and provide a more personalized experience.
            </p>

            <h4 className="font-semibold mt-4">Cookie Consent Tool</h4>
            <p className="mt-2">
                Upon your first visit, you will be prompted to accept or reject non-essential cookies. You can manage your cookie preferences through
                our Cookie Consent Tool or your web browser settings. Disabling cookies may affect your ability to use certain features of the Services.
            </p>

            <h3 className="font-bold mt-6">4. Third-Party Services</h3>
            <p className="mt-2">
                We may use third-party services to enhance our Services, including:
            </p>

            <h4 className="font-semibold mt-4">4.1. Analytics</h4>
            <ul className="list-disc ml-6">
                <li>
                    <strong>Google Analytics:</strong> We use Google Analytics to evaluate the use of our Services, compile reports on activity and events,
                    collect demographic data, and analyze performance metrics. Google Analytics may use cookies and similar tracking technologies to collect
                    and analyze information about your use of the Services.
                </li>
            </ul>

            <h4 className="font-semibold mt-4">4.2. Email Marketing</h4>
            <ul className="list-disc ml-6">
                <li>
                    <strong>MailChimp and Similar Services:</strong> We use email marketing services like MailChimp to send marketing communications,
                    newsletters, and other information you may find interesting. You can opt out of receiving such communications at any time by following
                    the unsubscribe link provided in the emails.
                </li>
            </ul>

            <h4 className="font-semibold mt-4">4.3. Advertising</h4>
            <ul className="list-disc ml-6">
                <li>
                    <strong>Google Ads and Similar Platforms:</strong> We may use Google Ads and similar advertising networks to display ads to you on our
                    Site or on third-party websites. These services use cookies and other tracking technologies to collect information about your online
                    activities and to show you targeted advertisements.
                </li>
            </ul>

            <h3 className="font-bold mt-6">5. How We Share Your Information</h3>
            <p className="mt-2">
                We may share your information in the following circumstances:
            </p>

            <ul className="list-disc ml-6">
                <li>
                    <strong>With Affiliates:</strong> We may share your information with our parent companies, affiliates, subsidiaries, joint ventures,
                    or other companies under common control.
                </li>
                <li>
                    <strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, such as in response to lawful
                    requests by public authorities, court orders, or regulatory agencies.
                </li>
                <li>
                    <strong>Business Transfers:</strong> In the event of a merger, acquisition, reorganization, sale of assets, or bankruptcy, we may
                    transfer your information to the relevant third party.
                </li>
                <li>
                    <strong>Protection of Rights:</strong> We may disclose your information when we believe it is necessary to protect the rights,
                    property, or safety of Chainflow Ltd, our users, or others.
                </li>
                <li>
                    <strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers who perform services
                    on our behalf, such as data analysis, email delivery, hosting services, and customer service.
                </li>
                <li>
                    <strong>Analytics Partners:</strong> Such as Google Analytics, to understand usage patterns.
                </li>
                <li>
                    <strong>Marketing Partners:</strong> With your consent, to send promotional materials.
                </li>
                <li>
                    <strong>Legal and Regulatory Authorities:</strong> When required by law.
                </li>
            </ul>

            <h3 className="font-bold mt-6">6. Your Rights Under GDPR</h3>
            <p className="mt-2">
                Under the General Data Protection Regulation (GDPR) and applicable EU laws, you have certain rights regarding your personal information:
            </p>

            <h4 className="font-semibold mt-4">6.1. Access</h4>
            <p className="mt-2">
                You have the right to request access to and obtain a copy of your personal information.
            </p>

            <h4 className="font-semibold mt-4">6.2. Rectification</h4>
            <p className="mt-2">
                You have the right to request corrections to any inaccuracies in your information.
            </p>

            <h4 className="font-semibold mt-4">6.3. Erasure (“Right to Be Forgotten”)</h4>
            <p className="mt-2">
                You have the right to request the deletion of your personal information under certain conditions, such as when the information is no longer
                necessary for the purposes for which it was collected or when you withdraw your consent.
            </p>

            <h4 className="font-semibold mt-4">6.4. Restriction of Processing</h4>
            <p className="mt-2">
                You have the right to request that we restrict the processing of your personal information under certain circumstances.
            </p>

            <h4 className="font-semibold mt-4">6.5. Data Portability</h4>
            <p className="mt-2">
                You have the right to receive your personal data in a structured, commonly used, and machine-readable format and to transmit that data to
                another data controller.
            </p>

            <h4 className="font-semibold mt-4">6.6. Object to Processing</h4>
            <p className="mt-2">
                You have the right to object to the processing of your personal data in certain situations, including when the processing is for direct
                marketing purposes.
            </p>

            <h4 className="font-semibold mt-4">6.7. Withdraw Consent</h4>
            <p className="mt-2">
                You have the right to withdraw your consent at any time where we are relying on consent to process your personal data.
            </p>

            <h4 className="font-semibold mt-4">6.8. Lodge a Complaint</h4>
            <p className="mt-2">
                You have the right to lodge a complaint with a supervisory authority in your EU country of residence or where the alleged infringement occurred.
            </p>

            <h4 className="font-semibold mt-4">How to Exercise Your Rights</h4>
            <p className="mt-2">
                To exercise any of these rights, please contact us at
                <a href="mailto:support@paradyze.io" className="text-blue-500 hover:underline">support@paradyze.io</a>.
                We will respond to your request within one month.
            </p>

            <h3 className="font-bold mt-6">7. Data Retention</h3>
            <p className="mt-2">
                We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable
                laws and regulations.
            </p>

            <h4 className="font-semibold mt-4">Retention Periods</h4>
            <ul className="list-disc ml-6">
                <li><strong>Contact Information:</strong> Retained for up to 2 years after your last interaction with us.</li>
                <li><strong>Transaction Data:</strong> Retained for 7 years to comply with tax and accounting regulations.</li>
                <li><strong>Cookies and Tracking Data:</strong> Retained according to our Cookie Policy.</li>
            </ul>

            <h3 className="font-bold mt-6">8. Security Measures</h3>
            <p className="mt-2">
                We are committed to ensuring the security of your data. We implement industry-standard security protocols, including:
            </p>

            <ul className="list-disc ml-6">
                <li><strong>Encryption:</strong> Protecting data during transmission and storage.</li>
                <li><strong>Access Controls:</strong> Limiting access to authorized personnel.</li>
                <li><strong>Regular Audits:</strong> Monitoring systems for vulnerabilities.</li>
                <li><strong>Administrative, Technical, and Physical Safeguards</strong></li>
            </ul>

            <p className="mt-2">
                However, please note that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee
                absolute security. You are responsible for maintaining the confidentiality of your account information and for all activities that occur
                under your account.
            </p>

            <h3 className="font-bold mt-6">9. International Data Transfers</h3>
            <p className="mt-2">
                Your personal information may be transferred to and processed in countries other than the country in which you are resident, including countries
                outside the European Economic Area (EEA), such as the United States.
            </p>

            <h4 className="font-semibold mt-4">Safeguards for International Transfers</h4>
            <p className="mt-2">
                We ensure appropriate safeguards are in place, including:
            </p>

            <ul className="list-disc ml-6">
                <li><strong>Standard Contractual Clauses</strong> approved by the European Commission.</li>
                <li><strong>Adequacy Decisions:</strong> For countries deemed to provide an adequate level of data protection.</li>
            </ul>

            <p className="mt-2">
                These measures ensure your personal data is protected according to EU standards.
            </p>
            <h3 className="font-bold mt-6">10. Children’s Privacy</h3>
            <p className="mt-2">
                Our Services are not intended for individuals under the age of 16, and we do not knowingly collect personal information from individuals
                under 16. If we become aware that we have collected personal information from an individual under this age without verification of parental
                consent, we will take steps to delete that information.
            </p>

            <h3 className="font-bold mt-6">11. Automated Decision-Making</h3>
            <p className="mt-2">
                We do not use automated decision-making processes that produce legal effects concerning you. If this changes, we will update this policy
                and notify you accordingly.
            </p>

            <h3 className="font-bold mt-6">12. Public Information</h3>
            <p className="mt-2">
                Some personal information is public due to the nature of blockchain technology. This may include your web3-enabled wallet’s public address,
                profile photo, and public transactions. Public information may be seen, accessed, reshared, or downloaded through APIs, SDKs, or third-party
                services that integrate with our products.
            </p>

            <h3 className="font-bold mt-6">13. Third-Party Links</h3>
            <p className="mt-2">
                Our Site and Services may contain links to third-party websites. We do not control and are not responsible for the privacy practices of
                these third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.
            </p>

            <h3 className="font-bold mt-6">14. Updates to This Privacy Policy</h3>
            <p className="mt-2">
                We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or for other operational,
                legal, or regulatory reasons.
            </p>

            <h4 className="font-semibold mt-4">Notification of Changes</h4>
            <p className="mt-2">
                Significant changes will be communicated via:
            </p>
            <ul className="list-disc ml-6">
                <li><strong>Email Notification:</strong> Sent to the address associated with your account.</li>
                <li><strong>Website Announcement:</strong> Posted on our homepage.</li>
            </ul>
            <p className="mt-2">
                When we make changes to this Privacy Policy, we will update the “Effective Date” at the top of this page and notify you as required
                by applicable law.
            </p>

            <h3 className="font-bold mt-6">15. Definitions</h3>
            <ul className="list-disc ml-6">
                <li><strong>“Personal Data”:</strong> Information relating to an identified or identifiable individual.</li>
                <li><strong>“Processing”:</strong> Any operation performed on personal data, such as collection, use, storage, or disclosure.</li>
            </ul>

            <h3 className="font-bold mt-6">16. Right to Lodge a Complaint</h3>
            <p className="mt-2">
                If you believe we are not complying with data protection laws, you have the right to lodge a complaint with a supervisory authority in your
                EU country of residence or where the alleged infringement occurred.
            </p>

            <h3 className="font-bold mt-6">17. Contact Us</h3>
            <p className="mt-2">
                If you have any questions or concerns about this Privacy Policy or our data practices, or if you wish to exercise your rights regarding your
                personal information, please contact us at:
            </p>
            <p className="mt-2">
                Email: <a href="mailto:support@paradyze.io" className="text-blue-500 hover:underline">support@paradyze.io</a>
            </p>


        </div>
    )
}

export default Privacy