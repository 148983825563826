import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { NETWORKS } from "../../utils/constants";
import { IndexerGrpcAccountApi, MsgBatchCancelBinaryOptionsOrders } from "@injectivelabs/sdk-ts";
import { getOfflineSigner, handleSendTx } from "../../utils/transaction";
import { Buffer } from "buffer";


const MyOrderHistory = (props) => {

    const networkConfig = useMemo(() => {
        return NETWORKS[import.meta.env.VITE_CHAIN_ID];
    }, []);

    const connectedAddress = useSelector(state => state.wallet.connectedAddress);
    const [subAccount, setSubAccount] = useState(null)

    const [totalMargin, setTotalMargin] = useState(null)

    const [onChainMarket, setOnChainMarket] = useState(null)
    const [marketSettled, setMarketSettled] = useState(false)
    const [orders, setOrders] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])

    const getSubAccount = useCallback(async (injectiveAddress: string) => {
        const indexerGrpcAccountApi = new IndexerGrpcAccountApi(networkConfig.indexer)
        const subaccountsList = await indexerGrpcAccountApi.fetchSubaccountsList(
            injectiveAddress,
        )
        setSubAccount(subaccountsList[0])
        return subaccountsList
    }, [networkConfig])

    useEffect(() => {
        setOnChainMarket(props.onChainMarket)
        setOrders(props.orders)
    }, [props.onChainMarket, props.orders])

    useEffect(() => {
        if (orders && subAccount) {
            const filteredOrders = orders.filter(order => order.subaccountId == subAccount)
            setFilteredOrders(filteredOrders)
        }
    }, [orders, subAccount])

    useEffect(() => {
        if (filteredOrders) {
            const totalMargin = filteredOrders.filter(order => order.state == "filled").reduce((sum, order) => {
                return sum + parseFloat(order.margin);
            }, 0);
            setTotalMargin((totalMargin / Math.pow(10, 6)).toFixed(2))
        }
    }, [filteredOrders])

    useEffect(() => {
        if (onChainMarket) {
            setMarketSettled(moment.unix(onChainMarket.settlementTimestamp).isBefore(moment()))
        }
    }, [onChainMarket])

    useEffect(() => {
        if (!connectedAddress) {
            setSubAccount(null)
            setOrders([])
        }
        else {
            getSubAccount(connectedAddress)
        }
    }, [connectedAddress, getSubAccount])

    const cancelOrder = useCallback(async (order) => {
        if (!connectedAddress) return
        const { key, offlineSigner } = await getOfflineSigner(networkConfig.chainId);
        const pubKey = Buffer.from(key.pubKey).toString("base64");
        const orders = [
            {
                marketId: order.marketId,
                subaccountId: order.subaccountId,
                orderHash: order.orderHash
            }
        ]
        const messages = orders.map((order) =>
            MsgBatchCancelBinaryOptionsOrders.fromJSON({
                injectiveAddress: connectedAddress,
                orders: [
                    {
                        marketId: order.marketId,
                        subaccountId: order.subaccountId,
                        orderHash: order.orderHash
                    }
                ]
            })
        )
        await handleSendTx(networkConfig, pubKey, messages, connectedAddress, offlineSigner)
        props.setOrdersLoaded(false)
    }, [connectedAddress, networkConfig, props])

    return (
        <div>
            <div
                className="text-right text-sm"
            >
                Volume: {totalMargin} USDT
            </div>
            <div className="flex justify-center items-center">
                <div className="w-full">
                    {filteredOrders.length == 0 &&
                        <div className="text-center mt-5 text-sm">
                            {connectedAddress ? "No orders yet..." : "Connect your wallet to see your orders"}
                        </div>
                    }
                    <div className="flex flex-row">
                        {filteredOrders.length > 0 &&
                            <div className="overflow-x-auto mt-1 text-sm max-h-40 overflow-y-scroll  w-full">
                                <table className="table-auto w-full">
                                    <thead className="text-white text-left">
                                        <tr>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Time
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Side
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Type
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Price
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Shares
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Margin
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                Filled
                                            </th>
                                            <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                State
                                            </th>
                                            {!marketSettled &&
                                                <th className="py-2 px-2 text-transparent bg-clip-text bg-gradient-to-r from-text-gold-gradient-start to-text-gold-gradient-end">
                                                    Actions
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm">
                                        {filteredOrders.map((order, index) => (
                                            <tr
                                                key={index}
                                                className="text-white border-b text-left"
                                            >
                                                <td className="py-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                                </td>
                                                <td className="py-1 px-2 ">
                                                    {order.direction == "buy" ?
                                                        <div
                                                            className="text-emerald-500"
                                                        >
                                                            YES
                                                        </div> :
                                                        <div
                                                            className="text-rose-500"
                                                        >
                                                            NO
                                                        </div>
                                                    }
                                                </td>
                                                <td className="py-1 px-2">
                                                    {order.executionType}
                                                </td>
                                                <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {(
                                                        order.direction === "sell"
                                                            ? 1 - Number(order.price) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                            : Number(order.price) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)
                                                    ).toFixed(4)}{" "}
                                                    {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                                </td>
                                                <td className="py-1 px-2">
                                                    {order.quantity}
                                                </td>
                                                <td className="py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {Number(order.margin) / Math.pow(10, onChainMarket.quoteToken ? onChainMarket.quoteToken.decimals : 6)}  {onChainMarket.quoteToken ? onChainMarket.quoteToken.symbol : "USDT"}
                                                </td>
                                                <td className="py-1 px-2">
                                                    {(order.filledQuantity / order.quantity * 100).toFixed(2)}%
                                                </td>
                                                <td className="py-1 px-2">
                                                    {order.state}
                                                </td>
                                                {!marketSettled &&
                                                    <td className="py-1 px-2">
                                                        {order.state == "booked" && subAccount !== null && order.subaccountId == subAccount &&
                                                            <div
                                                                onClick={() => cancelOrder(order)}
                                                                className="p-1 rounded text-xs hover:cursor-pointer text-center border border-gold text-gold font-bold"
                                                            >
                                                                Cancel
                                                            </div>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyOrderHistory;
